export function validateIcon(imgFile) {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.src = URL.createObjectURL(imgFile);
        img.onload = function(){
            let width = img.naturalWidth, height = img.naturalHeight;
            if (width !== 32 || height !== 24) {
                resolve(false);
            }
            else if (imgFile.type !== "image/png") {
                resolve(false);
            }
            else {
                resolve(true);
            }
        }
    });
}

export function validateTitleIcon(icons, newName, order)  {
    try {
        const resp = icons.filter( (icon) =>  icon.title.toLowerCase() === newName.toLowerCase() && icon.value.toString() !== order.toString() )

        if(resp.length === 0) return true
        return false
    } catch (error) {
        return false
    }
}
