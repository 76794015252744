// token
export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';

// counts
export const MOUSE_MOVE_COUNT = 'MOUSE_MOVE_COUNT';
export const MOUSE_CLICK_COUNT = 'MOUSE_CLICK_COUNT';
export const MOUSE_DBCLICK_COUNT = 'MOUSE_DBCLICK_COUNT';
export const MOUSE_DOWN_COUNT = 'MOUSE_DOWN_COUNT';
export const MOUSE_UP_COUNT = 'MOUSE_UP_COUNT';
export const MOUSE_CLEAN_COUNT = 'MOUSE_CLEAN_COUNT';
