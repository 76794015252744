import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "@nivo/recompose";
import { removeToken } from "redux/actions/token";
import PropTypes from "prop-types";
import { styles } from "./FooterStyle";

const Footer = (props) => {
  const { classes } = props;

  return (
    <div className={classes.footer}>
      <div>
        <div>
          <div>
            Copyright &copy; {new Date().getFullYear()} Xscope INC. All rights
            reserved.
          </div>
          <div>
            <a href="/Terms" target="_blank">
              Term of Use | Privacy Policy
            </a>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { token } = state;
  return { token };
};

const mapDispatchToProps = { removeToken };

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Footer);
