import { useState } from "react";

const useSessionStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = sessionStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (err) {
            return initialValue;
        }
    });

    const setValue = value => {
        try {
            setStoredValue(value);
            sessionStorage.setItem(key, JSON.stringify(value));
        } catch (err) {
            console.log(err);
        }
    }

    const removeValue = () => {
        try {
            sessionStorage.removeItem(key);
        } catch (err) {
            console.log(err);
        }
    }

    return [storedValue, setValue, removeValue];
}

export default useSessionStorage