import { createContext, useContext, useState } from "react";

const MapInfoContext = createContext(null);

const MapInfoContextProvider = ({ children }) => {
  const [globalDrawLayers, setGlobalDrawLayers] = useState(null);
  const [globalLayerValue, setGlobalLayerValue] = useState(null);
  const [globalSummary, setGlobalSummary] = useState(null);

  return (
    <MapInfoContext.Provider
      value={{
        globalDrawLayers,
        setGlobalDrawLayers,
        globalLayerValue,
        setGlobalLayerValue,
        globalSummary,
        setGlobalSummary,
      }}
    >
      {children}
    </MapInfoContext.Provider>
  );
};

export { MapInfoContext, MapInfoContextProvider };
