import { Button, Dialog, DialogContent, DialogTitle, Grid, withStyles } from "@material-ui/core"
import styles from "./styles";
import { useNavigate } from "react-router-dom";
import ContextAuth from "contexts/Auth.context";
import ContextSettings from "contexts/Settings.context";
import { useState } from "react";
import ContextCredits from "contexts/Credits.context";
import app_creditsServices from "services/app_credits.services";
import Swal from "sweetalert2";

const ModalBuyPhotos = ({classes,isOpen, handleClose,refetchTotalPhotos,photosPurchased, photosAvailable}) => {
    const navigate = useNavigate();
    const { subscribe } = ContextAuth();
    const { settings } = ContextSettings();
    const { total,discountCredits } = ContextCredits()
    const [counter, setCounter] = useState(1);

    const getCreditPrice = () => {
        return subscribe.name === "Free" ? settings.p_credits_photo_free : settings.p_credits_photo_su;
    }

    const getMaxPhotos = () => {
        return subscribe.name === "Free" 
                ? (settings.limitPhotosFree) 
                : subscribe.period === "year" ? parseInt(settings.limitPhotosSingleU) * 12 : parseInt(settings.limitPhotosSingleU)
    }

    const buyPhotos = async () => {
        const credits = getCreditPrice() * counter;

        if( (total - credits) < 0 ) {
            Swal.fire({
                icon: "error",
                text: `You don't have credits necessary.`,
                showConfirmButton: true,
                confirmButtonText: `Buy now`,
                showCancelButton: true,
                allowOutsideClick: true
            }).then( (resp) =>  resp.isConfirmed ? navigate("/credits/plans") : () => {} )
            .finally( () => handleClose() )
            return
        }

        const resp = await app_creditsServices.buyCreditsForPhotos(counter);
        if(!resp) {
            Swal.fire({
                icon: "error",
                text: `An error occurred, please try again later`,
            }).finally( () => handleClose() )
            return
        }

        discountCredits(credits)
        Swal.fire({
            icon: "success",
            text: `${counter} photos are recharged to your account.`,
        }).finally(() => {
            refetchTotalPhotos()
            handleClose()
        });
        //callback() 
    }

    const navigateToCredits = () => {
        navigate("/Profile/subscribe")
        handleClose()
    }

    return (
        <Dialog
            className={classes.dialog}
            open={isOpen}
            onClose={handleClose}
        >
            <DialogTitle disableTypography={true}>
                {
                    photosPurchased > 0 
                    ? <div> You have no photos available</div>
                    : <div> You have exceeded the project photo limit</div>
                }
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    justifyContent="center"
                    style={{
                        marginTop: "-5px",
                        marginBottom: 10,
                    }}
                    className={classes.subtitle}
                >
                    {
                        photosPurchased > 0 
                        ? <p style={{margin: "5px 0"}}>
                            Your have only {photosAvailable < 0 ? 0 : photosAvailable} photo available, but you can use {getCreditPrice()} credits per photo or 
                            upgrade your plan&nbsp;
                            <spam style={{color: "#FF844B"}} onClick={navigateToCredits}>here</spam>.
                        </p>
                        : <p style={{margin: "5px 0"}}>
                            Your plan only allows {getMaxPhotos()} photos, but you can use {getCreditPrice()} credits per photo or 
                            upgrade your plan&nbsp;
                            <spam style={{color: "#FF844B"}} onClick={navigateToCredits}>here</spam>.
                        </p>
                    }
                </Grid>
                <Grid
                    container
                    justifyContent="center"
                    style={{
                        marginTop: "-5px",
                        marginBottom: 42,
                    }}
                    className={classes.containerCount}
                >
                    <p style={{margin: "5px 0"}}>
                        How many photos do you want to buy?
                    </p>
                    <div>
                        <button className={classes.btnCount} onClick={() => counter > 1 && setCounter(prev => prev - 1)}>-</button>
                        <input className={classes.inputCount} type="number" disabled value={counter} />
                        <button className={classes.btnCount} onClick={() => setCounter(prev => prev + 1)}>+</button>
                    </div>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            type="submit"
                            variant="contained"
                            className={classes.submitButton}
                            onClick={buyPhotos}
                        >
                            Use {getCreditPrice() * counter} credits
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            color="default"
                            className={classes.cancelButton}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
export default withStyles(styles)(ModalBuyPhotos);