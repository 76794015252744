import ReactGA from "react-ga";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RouteChangeTracker = () => {
    const location = useLocation();

    useEffect(() => {
      try {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
      } catch (err) {
        console.log(err);
      }
    }, [location]);
  
    return <div></div>;
}

export default RouteChangeTracker