import { MonetizationOn } from "@material-ui/icons"
import { StyledCredits } from "./styles"
import { Link } from "react-router-dom"
import ContextCredits from "contexts/Credits.context"
import ContextAuth from "contexts/Auth.context"

const CreditsCard = () => {
    const { subscribe } = ContextAuth();
    const {total} = ContextCredits()

    if(subscribe.name !== "Free") return <></>

    return (
        <Link
            to="/credits"
            style={{ display: "flex", textDecoration: "none" }}
        >
            <StyledCredits>
                <MonetizationOn />
                <p>{total}</p>
            </StyledCredits>
        </Link>
    )
}

export default CreditsCard