import axios from "axios";
import {
  BASE_URL,
  client_id_companycamp,
  client_secret_companycamp,
  uri_companycamp,
} from "utils/constants";
import { arrayBufferToBase64 } from "utils/util";

class CompanyCampService {
  // token = "WfZwTY2eW4R47lxRV7g2CYTIcsOz9PmsIFJIc-sA864";
  // token = "qBaFchXGCuabs9EwdHxb7rpK76BYV5DUXx_yPiHGuFU";
  async getProject(parameters = {}, token) {
    let result;
    const params = new URLSearchParams(parameters);
    const URL = `https://api.companycam.com/v2/projects?${params.toString()}`;
    try {
      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      result = { status: true, data: response.data };
    } catch (err) {
      console.log(err);
      result = {
        status: false,
        message: "Could not connect to service",
      };
    }
    return result;
  }

  async saveProject(parameters = {}, token) {
    let result;
    const URL = `https://api.companycam.com/v2/projects`;
    try {
      const response = await axios.post(URL, parameters, {
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      result = { status: true, data: response.data };
    } catch (err) {
      result = {
        status: false,
        message: err.message,
      };
    }
    return result;
  }

  async updateProject(parameters = {}, projectID, token) {
    let result;
    const URL = `https://api.companycam.com/v2/projects/${projectID}`;
    try {
      const response = await axios.put(URL, parameters, {
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      result = { status: true, data: response.data };
    } catch (err) {
      result = {
        status: false,
        message: err.message,
      };
    }
    return result;
  }

  async getImages(projectID, token) {
    let result;
    const URL = `https://api.companycam.com/v2/projects/${projectID}/photos?page=1`;
    try {
      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      result = { status: true, data: response.data };
    } catch (err) {
      result = { status: false, message: "Could not connect to service" };
    }
    return result;
  }

  async getImageBase64(url) {
    let result;
    try {
      const response = await axios.get(url, { responseType: "arraybuffer" });
      const { data } = response;
      result = arrayBufferToBase64(data);
    } catch (err) {
      result = {
        status: "desconnected",
        message: "The image does not exist",
      };
    }
    return result;
  }

  async getToken(code) {
    let result;
    const params = {
      client_id: client_id_companycamp,
      client_secret: client_secret_companycamp,
      code,
      grant_type: "authorization_code",
      redirect_uri: uri_companycamp,
    };
    const URL = `https://app.companycam.com/oauth/token`;
    try {
      const response = await axios.post(URL, params);
      result = { status: true, data: response.data };
    } catch (err) {
      result = { status: false, data: null };
    }
    return result;
  }

  async refreshToken(refresh_token) {
    let result;
    const params = {
      client_id: client_id_companycamp,
      client_secret: client_secret_companycamp,
      refresh_token,
      grant_type: "refresh_token",
    };
    const URL = `https://app.companycam.com/oauth/token`;
    try {
      const response = await axios.post(URL, params);
      result = { status: true, data: response.data };
    } catch (err) {
      result = { status: false, data: null };
    }
    return result;
  }

  async getCurrentUser(token) {
    let result;
    const URL = `https://api.companycam.com/v2/users/current`;
    try {
      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      result = { status: true, data: response.data };
    } catch (err) {
      console.log(err);
      result = {
        status: false,
        message: "Could not connect to service",
      };
    }
    return result;
  }

  async updateInfoCompanycamp(params) {
    try {
      let url = `${BASE_URL}/api/webhookCompanyCamp/update-info-companycamp`;
      await axios.post(url, params);
    } catch (err) {
      console.log(err);
    }
  }
}

export default new CompanyCampService();
