import useLocalStorage from "Hooks/useLocalStorage";
import useSessionStorage from "Hooks/useSessionStorage";
import React, { createContext, useState } from "react";

const ChangeContext = createContext(null);

const ChangeProvider = ({ children }) => {
  // Variables that detect changes
  const [changeDrawing, setChangeDrawing] = useState(false);
  const [isChangedGeneralConditions, setIsChangedGeneralConditions] =
    useState(false);
  const [isChangedRoofComposition, setIsChangedRoofComposition] =
    useState(false);
  const [isChangedOverviewPictures, setIsChangedOverviewPictures] =
    useState(false);
  const [isChangedRoofSlope, setIsChangedRoofSlope] = useState(false);
  const [isChangedRoofDrainage, setIsChangedRoofDrainage] = useState(false);
  const [isChangedwallDetails, setIsChangedwallDetails] = useState(false);
  const [isChangedRoofDetails, setIsChangedRoofDetails] = useState(false);
  const [isChangeProjectInformation, setIsChangeProjectInformation] =
    useState(false);
  const [isSavingApp_measurements, setIsSavingApp_measurements] =
    useState(false);

  // Variables to validate the save
  const [overviewPicturesIsSaving, setOverviewPicturesIsSaving] =
    useState(false);

  // Variables to detect when photos have been taken or images have been uploaded
  const [overviewPicturesTakePhoto, setOverviewPicturesTakePhoto] =
    useState(false);
  const [GeneralConditionsTakePhoto, setGeneralConditionsTakePhoto] =
    useState(false);
  const [roofSlopeTakePhoto, setRoofSlopeTakePhoto] = useState(false);
  const [RoofCompositionTakePhoto, setRoofCompositionTakePhoto] =
    useState(false);
  const [RoofDrainageTakePhoto, setRoofDrainageTakePhoto] = useState(false);
  const [wallDetailsTakePhoto, setwallDetailsTakePhoto] = useState(false);
  const [RoofDetailsTakePhoto, setRoofDetailsTakePhoto] = useState(false);

  // In process or done
  const [stateInProcessOrDone, setstateInProcessOrDone] = useState(false);

  // Projects
  const [optionFilterChange, setOptionFilterChange] = useState(false);
  const [numberOfFiltres, setNumberOfFiltres] = useState(0);

  // notification counter
  const [count, setCount] = useState(0);

  // Connect and Disconnect Chat
  const [onlineUserChat, setOnlineUserChat] = useState([]);
  const [onlineUserChatDisconnect, setOnlineUserChatDisconnect] =
    useState(null);

  // Last Message Received
  const [lastMessageReceived, setLastMessageReceived] = useState(null);

  // New Comment
  const [new_comment, setNew_comment] = useState(null);

  // Message was read
  const [changeMesage, setChangeMesage] = useState({
    change: false,
    sender: "",
  });

  // New Chat Group
  const [newChatGroup, setNewChatGroup] = useState(null);

  // New Chat Group Notification
  const [newChatGroupNotification, setNewChatGroupNotification] =
    useState(null);

  // Delete Chat Group
  const [deleteChatGroup, setDeleteChatGroup] = useState("");

  // Delete Chat Group Notification
  const [deleteChatGroupNotification, setDeleteChatGroupNotification] =
    useState("");

  //update gropup
  const [updatedGroup, setUpdatedGroup] = useState("");

  // Update Chat Group
  const [clickUpdateChatGroup, setClickUpdateChatGroup] = useState({
    open: false,
    groupId: "",
  });
  const [updateChatGroup, setUpdateChatGroup] = useState(null);

  // Update Chat Group Notifications
  const [updateChatGroupNotifications, setUpdateChatGroupNotifications] =
    useState(null);

  // new project from calendar
  const [newProject, setNewProject] = useState(null);

  const [changeTextSearch, setchangeTextSearch] = useState(false);

  const [localSearch, setLocalSearch] = useState(null);

  const [isFirtsRenderHeader, setisFirtsRenderHeader] = useState(true);

  const [ss_search, setSs_search, removeSs_search] = useSessionStorage(
    "search",
    null
  );
  const [ss_optionFilter, setSs_optionFilter, removeSs_optionFilter] =
    useSessionStorage("optionFilter", null);
  const [ss_otherFilter, setSs_otherFilter, removeSs_otherFilter] =
    useSessionStorage("otherFilter", null);
  const [ss_numberOfFiltres, setSs_numberOfFiltres, removeSs_numberOfFiltres] =
    useSessionStorage("numberOfFiltres", null);

  const [
    ls_token_companyCamp,
    setLs_token_companyCamp,
    removeLs_Token_companyCamp,
  ] = useLocalStorage("token_CompanyCamp", null);

  // Guidlines Images
  const [guidlinesImages, setGuidlinesImages] = useState({});

  const [updateCompanycamIdAfterReload, setUpdateCompanycamIdAfterReload] =
    useState({
      status: false,
      value: null,
    });

  return (
    <ChangeContext.Provider
      value={{
        isChangedGeneralConditions,
        isChangedRoofComposition,
        isChangedOverviewPictures,
        isChangedRoofSlope,
        isChangedRoofDrainage,
        isChangedwallDetails,
        isChangedRoofDetails,
        isChangeProjectInformation,
        setIsChangedGeneralConditions,
        setIsChangedRoofComposition,
        setIsChangedOverviewPictures,
        setIsChangedRoofSlope,
        setIsChangedRoofDrainage,
        setIsChangedwallDetails,
        setIsChangedRoofDetails,
        setIsChangeProjectInformation,
        isSavingApp_measurements,
        setIsSavingApp_measurements,
        overviewPicturesIsSaving,
        setOverviewPicturesIsSaving,
        overviewPicturesTakePhoto,
        setOverviewPicturesTakePhoto,
        GeneralConditionsTakePhoto,
        setGeneralConditionsTakePhoto,
        RoofCompositionTakePhoto,
        setRoofCompositionTakePhoto,
        RoofDrainageTakePhoto,
        setRoofDrainageTakePhoto,
        wallDetailsTakePhoto,
        setwallDetailsTakePhoto,
        RoofDetailsTakePhoto,
        setRoofDetailsTakePhoto,
        roofSlopeTakePhoto,
        setRoofSlopeTakePhoto,
        stateInProcessOrDone,
        setstateInProcessOrDone,
        optionFilterChange,
        setOptionFilterChange,
        numberOfFiltres,
        setNumberOfFiltres,
        count,
        setCount,
        onlineUserChat,
        setOnlineUserChat,
        onlineUserChatDisconnect,
        setOnlineUserChatDisconnect,
        lastMessageReceived,
        setLastMessageReceived,
        new_comment,
        setNew_comment,
        changeMesage,
        setChangeMesage,
        newChatGroup,
        setNewChatGroup,
        newChatGroupNotification,
        setNewChatGroupNotification,
        deleteChatGroup,
        setDeleteChatGroup,
        deleteChatGroupNotification,
        setDeleteChatGroupNotification,
        clickUpdateChatGroup,
        setClickUpdateChatGroup,
        updateChatGroup,
        setUpdateChatGroup,
        updateChatGroupNotifications,
        setUpdateChatGroupNotifications,
        newProject,
        setNewProject,
        changeTextSearch,
        setchangeTextSearch,
        localSearch,
        setLocalSearch,
        isFirtsRenderHeader,
        setisFirtsRenderHeader,
        updatedGroup,
        setUpdatedGroup,
        ss_search,
        setSs_search,
        ss_otherFilter,
        setSs_otherFilter,
        removeSs_otherFilter,
        ss_optionFilter,
        setSs_optionFilter,
        ss_numberOfFiltres,
        setSs_numberOfFiltres,
        removeSs_search,
        removeSs_optionFilter,
        removeSs_numberOfFiltres,
        ls_token_companyCamp,
        setLs_token_companyCamp,
        removeLs_Token_companyCamp,
        guidlinesImages,
        setGuidlinesImages,
        updateCompanycamIdAfterReload,
        setUpdateCompanycamIdAfterReload,
        changeDrawing,
        setChangeDrawing,
      }}
    >
      {children}
    </ChangeContext.Provider>
  );
};

export { ChangeContext, ChangeProvider };
