import { Fragment } from "react";
import { ChangeProvider } from "./useContext/ChangeContext";
import { MapCountsProvider } from "./useContext/MapCountsContext";
import Header from "./header/Header";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import RouteChangeTracker from "./RouteChangeTracker/RouteChangeTracker";
import ContextAuth from "contexts/Auth.context";
import imgBackground from "assets/background/background.png";
import { TotalPicturesContextProvider } from "./useContext/TotalPictures.context";
import { CreditsContextProvider } from "contexts/Credits.context";
import { SettingsContextProvider } from "contexts/Settings.context";
import ContextsContainers from "./ContextsContainer";

const useStyles = makeStyles(() => ({
    container: {
        position: "fixed",
        width: "100vw",
        height: "100vh",
        padding: 0,
        boxSizing: "border-box",
        background: "#F1F6FA",
        overflowX: "hidden",
        overflowY: "auto",
    },
    content: {
        marginTop: 76,
        position: "fixed",
        width: "100vw",
        // height: "83vh",
        backgroundImage: `url(${imgBackground})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        padding: 0,
        boxSizing: "border-box",
        background: "#F1F6FA",
        overflowX: "hidden",
        overflowY: "auto",
    },
    contentEdit: {
        marginTop: 45,
        position: "fixed",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${imgBackground})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        boxSizing: "border-box",
        background: "#F1F6FA",
        overflowX: "hidden",
        overflowY: "auto",
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
    },
}));

const MainLayoutApp = ({ children }) => {
    const location = useLocation();
    const classes = useStyles();
    const {
        user, subscribe, initDataLogin, setSearch, projectName, profilePhoto, setNewMessage, setLastMessageReceived,
        setSession, userId, setUserId, device, setSendingUser
    } = ContextAuth();

    return (
        <ContextsContainers>
            <Fragment>
                <Header
                    subscribe={subscribe}
                    user={user}
                    setSearch={setSearch}
                    changeUser={initDataLogin}
                    projectName={projectName}
                    profilePhoto={profilePhoto}
                    setNewMessage={setNewMessage}
                    setLastMessageReceived={setLastMessageReceived}
                    setSession={setSession}
                    userId={userId}
                    setUserId={setUserId}
                    device={device}
                    setSendingUser={setSendingUser}
                >
                    <div
                        className={
                            location.pathname.indexOf("Edit") === 1
                                ? classes.contentEdit
                                : classes.content
                        }
                    >
                        {children}
                    </div>
                </Header>
                <RouteChangeTracker />
            </Fragment>
        </ContextsContainers>
    )
}

export default MainLayoutApp