import axios from "axios";
import { BASE_URL } from "utils/constants";

class SettingsService {
  async getAll() {
    var result = null;
    let url = `${BASE_URL}/api/settings/all`;

    await axios
      .get(url)
      .then((response) => {
        result = response.data.data;
      })
      .catch((err) => {
        result = null;
      });

    return result;
  }
}

export default new SettingsService();