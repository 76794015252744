import {
  MOUSE_MOVE_COUNT,
  MOUSE_CLICK_COUNT,
  MOUSE_DBCLICK_COUNT,
  MOUSE_DOWN_COUNT,
  MOUSE_UP_COUNT,
  MOUSE_CLEAN_COUNT
} from "../actionTypes";

const mouse_event = {};

export default function mouseCountsEvent(state = mouse_event, action) {
  switch (action.type) {
    case MOUSE_MOVE_COUNT:
      return {
        ...action,
      };
    case MOUSE_CLICK_COUNT:
      return {
        ...action,
      };
    case MOUSE_DBCLICK_COUNT:
      return {
        ...action,
      };
    case MOUSE_DOWN_COUNT:
      return {
        ...action,
      };
    case MOUSE_UP_COUNT:
      return {
        ...action,
      };
    case MOUSE_CLEAN_COUNT:
      return {
        ...action,
      };
    default:
      return state;
  }
}