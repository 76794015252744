import axios from "axios";
import { BASE_URL } from "utils/constants";

class SubscriptionService {
  async getSubscriptionPlans() {
    var result = null;
    let url = `${BASE_URL}/api/subscription/plans`;

    await axios
      .get(url)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        console.log(err);
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
  
  async getSubscriptionInit(userId) {
    var result = null;
    let url = `${BASE_URL}/api/subscription/getInit`;

    await axios
      .get(url, {
        params: {
          userId: userId,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        console.log(err);
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getSubscriptionInitSpecialCustomer() {
    var result = null;
    let url = `${BASE_URL}/api/subscription/getInitSpecialCustomer`;

    await axios
      .get(url)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        console.log(err);
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getSubscriptions() {
    var result = null;
    let url = `${BASE_URL}/api/subscription/get`;

    await axios
      .get(url)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        console.log(err);
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
  async addUpdateSubscription(data) {
    var result = null;
    let url = `${BASE_URL}/api/subscription/addUpdate`;

    await axios
      .post(url, data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async closeMessageSubscription() {
    var result = null;
    let url = `${BASE_URL}/api/subscription/closeMessage`;

    await axios
      .put(url)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async cancelSubscription(subscriptionId) {
    var result = null;
    let url = `${BASE_URL}/api/subscription/cancel`;

    await axios
      .delete(url, {
        params: {
          subscriptionId: subscriptionId,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
  async cancelActiveSubscription(subscriptionId) {
    var result = null;
    let url = `${BASE_URL}/api/subscription/cancel/active`;

    await axios
      .delete(url, {
        params: {
          subscriptionId: subscriptionId,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
}

export default new SubscriptionService();
