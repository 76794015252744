import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./app.router";
import AuthRouter from "./auth.router";
import { useSelector } from 'react-redux';
import ContextAuth from 'contexts/Auth.context';
import MainLayoutApp from 'components/MainLayoutApp';
import { CircularProgress } from '@material-ui/core';
import imgLogo from "assets/images/Logo.png";
import OauthRouter from './oauth.router';
import ContextsContainers from 'components/ContextsContainer';

const RootRouter = () => {
    const token = useSelector(state => state.token);
    const { loading } = ContextAuth();

    if (loading) {
        return (
            <div style={{
                width: "100%",
                height: "100%",
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                backgroundColor: "#FF844B"
            }}>
                <img
                    src={imgLogo}
                    alt="Logo"
                    style={{ width: "124.12px", height: 40 }}
                />
                <CircularProgress size={30} style={{ color: "white" }} />
            </div>
        )
    }

    if (new URLSearchParams(window.location.search).get('oauthflow') === "true") {
        return(
            <Router basename="/">
                <ContextsContainers><OauthRouter/></ContextsContainers>
            </Router>
        )
    }

    return (
        <Router basename="/">
            {token
                ? <MainLayoutApp><AppRouter /></MainLayoutApp>
                : <AuthRouter />
            }
        </Router>
    );
}

export default RootRouter