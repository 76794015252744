import axios from "axios";
import { BASE_URL } from "utils/constants";

class app_creditService {
  async getTotal() {
    let result;
    const url = `${BASE_URL}/api/credits/getTotal`;

    await axios
      .get(url)
      .then((response) => {
        result = response.data.data;
      })
      .catch((err) => {
        result = 0;
      });

    return result;
  }
  async getHistory() {
    let result;
    const url = `${BASE_URL}/api/credits/getHistory`;

    await axios
      .get(url)
      .then((response) => {
        result = response.data.data;
      })
      .catch((err) => {
        result = [];
      });

    return result;
  }
  async getPlans() {
    let result;
    const url = `${BASE_URL}/api/credits/getPlans`;

    await axios
      .get(url)
      .then((response) => {
        result = response.data.data;
      })
      .catch((err) => {
        result = [];
      });

    return result;
  }

  async buyCreditsForReport(mode) {
    let result;
    const url = `${BASE_URL}/api/credits/buyCreditsForReport?mode=${mode}`;

    await axios
      .get(url)
      .then((response) => {
        result = response.data.data;
      })
      .catch((err) => {
        result = null;
      });

    return result;
  }

  async buyCreditsForPhotos(amount) {
    let result;
    const url = `${BASE_URL}/api/credits/buyCreditsForPhotos?amount=${amount}`;

    await axios
      .get(url)
      .then((response) => {
        result = response.data.data;
      })
      .catch((err) => {
        result = null;
      });

    return result;
  }

  async payCredits(data = {}) {
    let result;
    const url = `${BASE_URL}/api/credits/payCredits`;

    await axios
      .post(url, data)
      .then((response) => {
        result = response.data.data;
      })
      .catch((err) => {
        result = null;
      });

    return result;
  }
}

export default new app_creditService();