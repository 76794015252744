import styled from "styled-components";

export const StyledCredits = styled.div`
    padding: 0.5rem 1rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    cursor: pointer;
`