import axios from "axios";
import { BASE_URL } from "utils/constants";
import { arrayBufferToBase64 } from "utils/util";

class App_photos {
  async updateProjectPhotos(parameters = {}) {
    let result;
    const url = `${BASE_URL}/api/photos/update_photos_companycamp`;
    try {
      const response = await axios.put(url, parameters);
      result = response.data;
    } catch (err) {
      result = {
        status: "desconnected",
        message: "Could not connect to service",
      };
    }
    return result;
  }
  async getCompanyCamPhotosById(projectId) {
    let result;
    const url = `${BASE_URL}/api/photos/get-companycam-photos/${projectId}`;
    try {
      const response = await axios.get(url);
      result = response.data;
    } catch (err) {
      result = {
        status: "desconnected",
        message: "Could not connect to service",
      };
    }
    return result;
  }

  async getTotalPhotos() {
    let result;
    const url = `${BASE_URL}/api/photos/getTotalPhotos`;
    await axios
      .get(url)
      .then((response) => {
        result = response.data.data;
      })
      .catch((err) => {
        result = null;
      });

    return result;
  }

  
  async getPhotosV2(project_id, imageSource, size = 10, page = 1) {
    let result;
    const url = `${BASE_URL}/api/photos/v2/getPhotos`;
    await axios
      .get(url, {params: { project_id, imageSource, size, page, onlyWithoutCondition: true}})
      .then((response) => {
        result = response.data.data.photos;
      })
      .catch((err) => {
        result = [];
      });

    return result;
  }


  async updateUsedCompanyCamPhotos(ids = []) {
    let result;
    const url = `${BASE_URL}/api/photos/update-used-companycam-photos`;
    try {
      const response = await axios.put(url, { ids });
      result = response.data;
    } catch (err) {
      result = {
        status: "desconnected",
        message: "Could not connect to service",
      };
    }
    return result;
  }
  async updateAppPhotosFromCompanyCam(images, imageSource, projectId) {
    let result;
    const url = `${BASE_URL}/api/photos/update-app-photos-from-companycam`;
    try {
      const response = await axios.post(url, {
        images,
        imageSource,
        projectId,
      });
      result = response.data;
    } catch (err) {
      result = {
        status: "desconnected",
        message: "Could not connect to service",
      };
    }
    return result;
  }
}

export default new App_photos();
