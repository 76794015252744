import axios from "axios";
import { BASE_URL } from "utils/constants";

class App_CompanyCamService {
  async addOrUpdateTokenCompanyCam(params) {
    var result = null;
    let url = `${BASE_URL}/api/companycamp/createOrUpdate`;

    await axios
      .post(url, params)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getPhotosOutOfSync(data) {
    var result = null;
    let url = `${BASE_URL}/api/companycamp/getPhotosOutOfSync`;

    await axios
      .post(url, data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async syncPhotos(data) {
    var result = null;
    let url = `${BASE_URL}/api/companycamp/sync-project-photos`;

    await axios
      .post(url, data)
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          result = {
            status: "success",
          };
        } else {
          result = {
            status: "error",
            message: response.message || "",
          };
        }
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async addProjectToCompanyCam(params) {
    var result = null;
    let url = `${BASE_URL}/api/companycamp/saveProject`;

    await axios
      .post(url, params)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
}

export default new App_CompanyCamService();
