export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const recaptcha = process.env.REACT_APP_RECAPTCHA;
export const googleAnalyticsKey = process.env.REACT_APP_GOOGLEANALYTICSKEY;
export const uri_companycamp = process.env.REACT_APP_URI_COMPANYCAMP;
export const BASE_URL_ESTIMATOR = process.env.REACT_APP_BASE_URL_ESTIMATOR;
export const client_id_companycamp =
  process.env.REACT_APP_CLIENT_ID_COMPANYCAMP;
export const client_secret_companycamp =
  process.env.REACT_APP_CLIENT_SECRET_COMPANYCAMP;
export const SITE_URL = process.env.REACT_APP_SITE_URL;

export const CRM_API = process.env.REACT_APP_CRM_API;

export const PHOTO_URL = BASE_URL + "/profilesImages/";

export const LOGO_URL = BASE_URL + "/companyImages/";
export const WIDTH_PROJECT_IMG = 1000
export const WIDTH_PROJECT_IMG_PICTURE = 800
export const QUALITY_PROJECT_IMG = 0.5

const getDate = new Date();

export const FOOTER_TEXT = `Copyright ©️ ${getDate.getFullYear()} www.x-scope.net`;

export const google = window.google;

export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoicmF1bGNhcnBpbyIsImEiOiJja25lbzVmMXMyaTA5Mm9waDRoN3JzeTluIn0.vv2P5-OjpjGPfEmA6NK5KA";

export const GOOGLE_MAP_KEY = "AIzaSyAQinYqZwKPrSLLnmEseLwuDmC8XbdIbFA";
export const GOOGLE_MAP_KEY_2 = "AIzaSyBrHZkK2-wJAuIb-SWW8F-TTSq6lTupLqM";
export const authIdGoogle =
  "539807153416-7ag4ur2e29bnndd22hcm3ivsq9hrj70f.apps.googleusercontent.com";

export const authIdFacebook = "457653606018345";

export const TOKEN_KEY_CARD = "client-keyXcopeHSL951e6S0I3e";

export const blankStyle = {
  version: 8,
  name: "blank",
  sources: {},
  glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
  layers: [
    {
      id: "background",
      type: "background",
      paint: {
        //"background-color": "#1d1f20"
        "background-color": "#fff",
      },
    },
  ],
};

export const rowsPerPage = 10; // view project counts per page on project page

export const selectedColor = "#000"; // selected color when edges/areas are selected

export const pitches = generatePitches();

function generatePitches() {
  var temp = [];
  for (var i = 0; i < 25; i++) {
    temp[`${i}/12`] = `${i} / 12`;
  }
  return temp;
}

// export const edges = {
//   eaves: { name: "Eaves", style: "solid", color: "rgb(0, 153, 41)" }, 
//   valleys: { name: "Valleys", style: "solid", color: "rgb(0, 153, 41)" }, 
//   hips: { name: "Hips", style: "solid", color: "rgb(153, 0, 255)" }, 
//   ridges: { name: "Ridges", style: "solid", color: "rgb(128, 128, 0)" }, 
//   rakes: { name: "Rakes", style: "solid", color: "rgb(209, 144, 90)" },
//   // rakes: { name: "Rakes", style: "solid", color: "rgb(144, 0, 32)" },
//   wall_flashing: {
//     name: "Wall Flashing",
//     style: "dashed",
//     color: "rgb(65, 135, 175)",
//   },
//   step_flashing: {
//     name: "Step Flashing",
//     style: "dashed",
//     color: "rgb(168, 107, 50)",
//   },
//   transition: {
//     name: "Transition",
//     style: "solid",
//     color: "rgb(255, 51, 204)", 
//   },
//   unspecified: {
//     name: "Unspecified",
//     style: "solid",
//     color: "rgb(85, 211, 252)", 
//   },
//   gutters: {
//     name: "Gutters",
//     style: "solid",
//     color: "rgb(30, 144, 255)", 
//   },
// };

export const edges = {
  eaves: { name: "Eaves", style: "solid", color: "rgb(0, 191, 0)" },
  valleys: { name: "Valleys", style: "solid", color: "rgb(255, 0, 0)" },
  hips: { name: "Hips", style: "solid", color: "rgb(147, 0, 255)" },
  ridges: { name: "Ridges", style: "solid", color: "rgb(200, 239, 0)" },
  rakes: { name: "Rakes", style: "solid", color: "rgb(255, 180, 0)" },
  wall_flashing: {
    name: "Wall Flashing",
    style: "dashed",
    color: "rgb(0, 135, 175)",
  },
  step_flashing: {
    name: "Step Flashing",
    style: "dashed",
    color: "rgb(168, 59, 0)",
  },
  transition: {
    name: "Transition",
    style: "solid",
    color: "rgb(251, 0, 255)",
  },
  unspecified: {
    name: "Unspecified",
    style: "solid",
    color: "rgb(0, 211, 255)",
  },
  gutters: {
    name: "Gutters",
    style: "solid",
    color: "rgb(0, 130, 255)",
  },
};

// export const edges = {
//   eaves: { name: "Eaves", style: "solid", color: "rgb(113, 191, 130)" },
//   valleys: { name: "Valleys", style: "solid", color: "rgb(240, 81, 46)" },
//   hips: { name: "Hips", style: "solid", color: "rgb(147, 104, 183)" },
//   ridges: { name: "Ridges", style: "solid", color: "rgb(208, 239, 177)" },
//   rakes: { name: "Rakes", style: "solid", color: "rgb(255, 204, 15)" },
//   wall_flashing: {
//     name: "Wall Flashing",
//     style: "dashed",
//     color: "rgb(65, 135, 175)",
//   },
//   step_flashing: {
//     name: "Step Flashing",
//     style: "dashed",
//     color: "rgb(168, 107, 50)",
//   },
//   transition: {
//     name: "Transition",
//     style: "solid",
//     color: "rgb(251, 104, 255)",
//   },
//   unspecified: {
//     name: "Unspecified",
//     style: "solid",
//     color: "rgb(85, 211, 252)",
//   },
//   gutters: {
//     name: "Gutters",
//     style: "solid",
//     color: "rgb(30, 144, 255)",
//   },
// };

export const roofAccessItems = [
  "Ladder",
  "Roof Hatch",
  "Stairwell to the Roof",
  "Exterior Access",
  "Elevator",
];

export const projectTypes = ["Steep Slope Roofs", "Low Slope Roofs"];

export const roofLifeCycleItems = [
  "Select",
  "A (Repair and maintenance +15 years)",
  "B (Repair and maintenance 10-14 years)",
  "C (Repair and maintenance 5-9 years)",
  "D (Restore 2-4 years)",
  "F (Re-roof 0-2 years)",
];

export const surfacingTypes = [
  "N/A",
  "BUR/Gravel",
  "CTP/Gravel",
  "MOD BIT",
  "Single Ply",
  "BUR",
];

export const attachmentItems = [
  "N/A",
  "Mechanically Fasten",
  "Mopped",
  "Adhered",
];

export const insulationTypes = [
  "N/A",
  "Wood Fiber",
  "Perlite",
  "ISO",
  "LWIC",
  "EPS",
  "Extruded",
  "SPF",
  "Dens Deck",
  "Securock",
];

export const roofDeckTypes = [
  "Steel",
  "Concrete",
  "Gypsum",
  "Tectum",
  "LWIC",
  "Wood",
];

export const slopeDefinitionItems = [
  "Select",
  "Structural Slope",
  "LWIC Slope",
  "Tapered Slope",
  "No Slope",
];

export const slopeValueItems = [
  "Select",
  "Flat",
  "1/8",
  "1/4",
  "1/2",
  "1/12",
  "2/12",
  "3/12",
  "4/12",
  "Other (add Text)",
];

export const drainageTypes = {
  "Internal Drains": {
    types: [
      "Main Primary Roof Drains",
      "Main Primary Dual Type Drains",
      "Main Primary Patio Deck Drains",
      "Retrofit Drains",
      // "Overflow Drains", "Main Drains"
    ],
    dimensions: { A: "", B: "", C: "" },
    first_dimensions: {},
    addCount: true,
  },
  "Primary Scuppers": {
    types: [
      "Primary Through Wall Scupper",
      "Primary Cant Strip Scupper",
      "Primary Open Top Scupper",
      "Circular Primary Scupper"
      // "Overflow Scuppers",
      // "Primary Scuppers",
      // "EOS",
      // "Primary open top scuppers",
    ],
    dimensions: { A: "", B: "", C: "" },
    first_dimensions: { A: "", B: "", C: "" },
    addCount: true,
  },
  Gutter: {
    types: ["Seamless", "Box", "K-Style"],
    dimensions: { A: "", B: "", C: "", D: "" },
    first_dimensions: {},
    addCount: false,
  },
  Downspout: {
    types: ["Seamless", "Box", "K-Style"],
    dimensions: { A: "", B: "", C: "" },
    first_dimensions: {},
    addCount: true,
  },
  Leaderheads: {
    types: [],
    dimensions: { A: "", B: "", C: "", D: "", E: "", F: "", G: "" },
    first_dimensions: {},
    addCount: true,
  },
};

export const sizeTypes = [
  "4″×4″",
  "6″×6″",
  "8″×8″",
  "10″×10″",
  "12″×12″",
  "16″×16″",
  "24″×24″",
];

export const materialTypes = [
  "Aluminium",
  // "Cast Iron",
  "Copper",
  "Galvanized",
  // "PVC",
  "Stainless Steel",
  "Other",
];

export const materialTypesACStandLegs = [
  "Aluminium",
  "Copper",
  "Galvanized",
  "Cast Iron",
  "Stainless Steel",
  "Other",
];

export const materialTypesInternalDrains = [
  "Aluminium",
  "Cast Iron",
  "Copper",
  "Galvanized",
  "PVC",
  "Stainless Steel",
  "Other",
];

export const materialCompositionItems = [
  "Select",
  "Wood",
  "Concrete",
  "Steel",
  "Other (add Text)",
];

export const metalTerminationFlashingsTypes = {
  "Counter Flashing": {
    types: [
      "Surface Mounted",
      "Stucco Stop",
      "1 Piece Reglet",
      "2 Piece Reglet",
      "Slip Flashing",
    ],
    dimensions: { A: "" },
    addLength: true,
    addCount: false,
    // dimensions: { A: "", B: "" },
  },
  "Edge Metal Termination": {
    types: ["Drip Edge", "Gravel Stop", "Edge Fascia", "Termination Bar"],
    dimensions: { A: "", B: "" },
    addLength: true,
    addCount: false,
    // dimensions: { A: "", B: "", C: "", D: "" },
  },
  Coping: {
    types: [],
    dimensions: {
      A: "",
      B: "",
      C: "",
      //D: ""
    },
    addLength: true,
    addCount: false,
  },
  "Door Threshold": {
    types: [],
    dimensions: {
      A: "",
      B: "",
      C: "",
      //D: ""
    },
    addLength: true,
    addCount: false,
  },
  "Expansion Joints": {
    types: [],
    dimensions: { A: "", B: "", C: "", D: "" },
    addLength: true,
    addCount: false,
  },
  "VTR’s": {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  "Pitch Pans": {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  "A/C Stands": {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  "A/C Units": {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  "A/C Stand Legs": {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  "A/C Units on Curbs": {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  "Roof Turbines": {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  "Roof Trash Chutes": {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  "Roof Square Gravity Vent": {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  "Roof Round Gravity Vent": {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  "Roof Smoke Hoods": {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  "Roof Ventilators": {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  "Roof Curbs": {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  "Roof Hatch": {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  Skylights: {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  "Goose Necks": {
    types: [],
    dimensions: { A: "", B: "", C: "" },
    addLength: false,
    addCount: true,
  },
  "Line Jacks": {
    types: [],
    dimensions: { A: "", B: "", C: "", D: "", E: "" },
    addLength: false,
    addCount: true,
  },
  "APV’s": {
    types: [],
    dimensions: { A: "" },
    addLength: false,
    addCount: true,
  },
  "Abandoned Equipment": {
    types: [],
    dimensions: {},
    addLength: false,
    addCount: true,
  },
  "Condensation Lines": {
    types: [],
    dimensions: {},
    addLength: true,
    addCount: false,
  },
  "Electrical Lines on roof": {
    types: [],
    dimensions: {},
    addLength: true,
    addCount: false,
  },
  "Electrical Lines on walls": {
    types: [],
    dimensions: {},
    addLength: true,
    addCount: false,
  },
  "Lightning Protection": {
    types: [],
    dimensions: {},
    addLength: true,
    addCount: false,
  },
};

export const initialOptions = {
  basicInformation: {
    projectName: "",
    projectType: projectTypes[0],
    address: "",
    location: { lat: 0, lng: 0 },
    city: "",
    state: "",
    zipCode: "",
    contactName: "",
    email: "",
    phone: "",
    mobilePhone: "",
    fax: "",
    id: 0,
    user_id: "",
  },
  propertyAccess: {
    buildingHeight: "",
    roofAccess: roofAccessItems[0],
  },
  miscellaneousInformation: {
    salesStatus: "",
    marketingStatus: "",
    bidDate: "",
    leadForm: "",
    estimator: "",
    accountManager: "",
    takeOffPerson: "",
    productsServices: "",
    clientProfile: "",
    contractType: "",
    bdm: "",
    hardBid: "",
  },
  overviewPictures: {
    images: [],
  },
  generalConditions: {
    roofLifeCycle: roofLifeCycleItems[0],
    roofComposition: [
      // {
      //   overBurden: "yes",
      //   gravel: "",
      //   pavers: "",
      //   tiles: "",
      //   woodDecks: "",
      //   other: "",
      //   roofNumbers: [
      //     {
      //       surfacingType: surfacingTypes[0],
      //       surfacingTypeThickness: "",
      //       attachment: attachmentItems[0],
      //       insulationType_1: insulationTypes[0],
      //       insulationTypeThickness_1: "",
      //       insulationType_2: insulationTypes[0],
      //       insulationTypeThickness_2: "",
      //       roofDeckType: roofDeckTypes[0],
      //       totalExistingRoofThickness: "",
      //       images: [],
      //     },
      //   ],
      // },
    ],
    roofDeckType: roofDeckTypes[0],
    totalExistingRoofThickness: "",
    images: [],
  },
  roofSlope: {
    slopeDefinition: slopeDefinitionItems[0],
    slopeValue: slopeValueItems[0],
  },
  roofDrainage: {
    drainageRating: "good", // good, poor
    drainageItems: [
      // {
      //   drainageType: Object.keys(drainageTypes)[0],
      //   items: [
      //     {
      //       overflowsPresents: "yes",
      //       size: "",
      //       type: drainageTypes[Object.keys(drainageTypes)[0]]["types"][0],
      //       material: materialTypes[0],
      //       imagesOverflows: [],
      //       dimensions:
      //         drainageTypes[Object.keys(drainageTypes)[0]]["dimensions"],
      //       images: [],
      //     },
      //   ],
      // },
    ],
  },
  wallDetails: {
    parapetWallInformation: "good",
    materialComposition: materialCompositionItems[0],
    stucco: "yes",
    wallProperties: [
      // {
      //   wallHeight: "",
      //   wallThinkness: "",
      //   wallFlashingHeight: "",
      //   woodNailer: "yes",
      //   images: [],
      // },
    ],
  },
  roofDetails: [
    // {
    //   metalTerminationFlashingsType: Object.keys(
    //     metalTerminationFlashingsTypes
    //   )[0],
    //   type: metalTerminationFlashingsTypes[
    //     Object.keys(metalTerminationFlashingsTypes)[0]
    //   ]["types"][0],
    //   size: "",
    //   materialType: materialTypes[0],
    //   dimensions:
    //     metalTerminationFlashingsTypes[
    //     Object.keys(metalTerminationFlashingsTypes)[0]
    //     ]["dimensions"],
    //   images: [],
    // },
  ],
};

export const initialUploadFiles = {
  overviewPictures: {
    files: [],
    images: [],
  },
  generalConditions: {
    files: [],
    images: [],
  },
  roofDrainage: [[{ files: [], images: [] }]],
  roofDrainageOverflows: [[{ files: [], images: [] }]],
  wallDetails: [
    {
      files: [],
      images: [],
    },
  ],
  roofDetails: [
    {
      files: [],
      images: [],
    },
  ],
  // roofNumbers:[
  //   {
  //     files: [],
  //     images: [],
  //   },
  // ],
  roofNumbers: [[{ files: [], images: [] }]],
};

export const customModalStyles = {
  blanket: (base) => ({
    ...base,
    backgroundColor: "#35454C", //#171d29
  }),
};

export const customCarouselStyles = {
  header: (base, state) => ({
    ...base,
    height: 56,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
    backgroundColor: "#35454C !important",
  }),
  view: (base, state) => ({
    ...base,
    "& img": {
      borderRadius: 8,
    },
  }),
  navigation: (base, state) => ({
    ...base,
    "& button": {
      backgroundColor: "#171d29",
    },
    "& button svg": {
      width: 24,
      height: 24,
    },
  }),
};

export const selectMenuProps = {
  MenuProps: {
    anchorOrigin: { vertical: "bottom", horizontal: "center" },
    transformOrigin: { vertical: "top", horizontal: "center" },
    getContentAnchorEl: null,
  },
};

// Changes 20-12-2021
export const app_measurementsInitial = {
  uid: "",
  user_id: "",
  admin_id: "",
  project_name: "",
  location: "",
  zoom: "",
  bearing: 0,
  geodata: "",
  options: "",
  status: "",
  slope: "",
  project_address: "",
  in_process_or_done: false,
  projectbyfilltype: false,
  isShared: false,
  createdAt: "",
  updatedAt: "",
  basicInformation: {
    projectName: "",
    projectType: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    contactName: "",
    email: "",
    phone: "",
    mobilePhone: "",
    fax: "",
    location: "",
  },
  propertyAccess: {
    buildingHeight: "",
    roofAccess: "",
  },
  miscellaneousInformation: {
    salesStatus: "",
    marketingStatus: "",
    bidDate: "",
    leadForm: "",
    estimator: "",
    accountManager: "",
    takeOffPerson: "",
    productsServices: "",
    clientProfile: "",
    contractType: "",
    bdm: "",
    hardBid: "",
  },
};

export const overviewPicturesInitial = {
  images: [],
};

export const overviewPicturesFilesInitial = {
  files: [],
  images: [],
  deleteImages: [],
  deleteImagesFolder: [],
};

// export const generalConditionsInitial = {
//   roofLifeCycle: roofLifeCycleItems[0],
//   images: [],
// }

export const generalConditionsInitial = [
  //   {
  //   areaName: 'Select',
  //   roofLifeCycle: roofLifeCycleItems[0],
  //   images: [],
  // }
];

// export const generalConditionsFilesInitial = {
//   files: [],
//   images: [],
// }

export const generalConditionsFilesInitial = {
  generalConditions: [
    {
      files: [],
      images: [],
      deleteImages: [],
      deleteImagesFolder: [],
    },
  ],
};

export const roofSlopeFilesInitial = {
  roofSlope: [
    {
      files: [],
      images: [],
      deleteImages: [],
      deleteImagesFolder: [],
    },
  ],
};

export const roofCompositionInitial = [];

export const roofNumbersFilesInitial = [
  [{ files: [], images: [], deleteImages: [], deleteImagesFolder: [] }],
];

export const roofSlopeInitial = [
  // {
  //   areaName: 'Select',
  //   slopeDefinition: slopeDefinitionItems[0],
  //   slopeValue: slopeValueItems[0],
  // }
];

// export const roofSlopeInitial = {
//   slopeDefinition: slopeDefinitionItems[0],
//   slopeValue: slopeValueItems[0],
// };

// export const roofDrainageInitial = {
//   drainageRating: "good",
//   drainageItems: [],
// };

export const roofDrainageInitial = [];

export const roofDrainageFilesInitial = {
  roofDrainage: [
    [{ files: [], images: [], deleteImages: [], deleteImagesFolder: [] }],
  ],
  roofDrainageOverflows: [
    [{ files: [], images: [], deleteImages: [], deleteImagesFolder: [] }],
  ],
};

// export const wallDetailsInitial = {
//   parapetWallInformation: "good",
//   materialComposition: materialCompositionItems[0],
//   stucco: "yes",
//   wallProperties: [],
// };

export const wallDetailsInitial = [];

export const wallDetailsFilesInitial = {
  wallDetails: [
    {
      files: [],
      images: [],
      deleteImages: [],
      deleteImagesFolder: [],
    },
  ],
};

export const roofDetailsInitial = [];

export const roofDetailsFilesInitial = {
  roofDetails: [
    {
      files: [],
      images: [],
      deleteImages: [],
      deleteImagesFolder: [],
    },
  ],
};

export const dashboardMonths = [
  { name: "All", value: 13 },
  { name: "January", value: 1 },
  { name: "February", value: 2 },
  { name: "March", value: 3 },
  { name: "April", value: 4 },
  { name: "May", value: 5 },
  { name: "June", value: 6 },
  { name: "July", value: 7 },
  { name: "August", value: 8 },
  { name: "September", value: 9 },
  { name: "October", value: 10 },
  { name: "November", value: 11 },
  { name: "December", value: 12 },
];

async function parseURI(d) {
  var reader = new FileReader();
  reader.readAsDataURL(d);
  return new Promise((res, rej) => {
    reader.onload = (e) => {
      res(e.target.result);
    };
  });
}

export async function getDataBlob(url) {
  var res = await fetch(url);
  var blob = await res.blob();
  var uri = await parseURI(blob);
  return uri;
}

export const maxSizeOfImage = 7340032; // bytes

export const maxSizeOfVideo = 50; // Megabytes

export const urlCalledFrom = {
  calledFromChat: "/Chat/Messenger",
  // calledFromPhotoComment: '/',
};

// localhost
// export const WEBHOOK_URL =
//   "https://ff0d-2001-1388-3fa1-b534-ac4f-9e0a-26c-ccac.sa.ngrok.io";
// export const typeWebHook = [
//   // {
//   //   scopes: ["project.created"],
//   //   url: `${WEBHOOK_URL}/api/webhookCompanyCamp/create-project`,
//   //   enabled: true,
//   // },
//   // {
//   //   scopes: ["project.updated"],
//   //   url: `${WEBHOOK_URL}/api/webhookCompanyCamp/update-project`,
//   //   enabled: true,
//   // },
//   {
//     scopes: ["photo.created"],
//     url: `${WEBHOOK_URL}/api/webhookCompanyCamp/add-photos-to-project`,
//     enabled: true,
//   },
//   {
//     scopes: ["photo.updated"],
//     url: `${WEBHOOK_URL}/api/webhookCompanyCamp/update-project-photos`,
//     enabled: true,
//   },
// ];

// production and testing
export const typeWebHook = [
  // {
  //   scopes: ["project.created"],
  //   url: `${BASE_URL}/api/webhookCompanyCamp/create-project`,
  //   enabled: true,
  // },
  // {
  //   scopes: ["project.updated"],
  //   url: `${BASE_URL}/api/webhookCompanyCamp/update-project`,
  //   enabled: true,
  // },
  {
    scopes: ["photo.created"],
    url: `${BASE_URL}/api/webhookCompanyCamp/add-photos-to-project`,
    enabled: true,
  },
  {
    scopes: ["photo.updated"],
    url: `${BASE_URL}/api/webhookCompanyCamp/update-project-photos`,
    enabled: true,
  },
];

export const imageSource = {
  generalConditions: "generalConditions",
  overviewPictures: "overviewPictures",
  roofCompositions: "roofCompositions",
  roofSlope: "roofSlope",
  roofDrainage: "roofDrainage",
  wallDetails: "wallDetails",
  roofDetails: "roofDetails",
};

export const typeOfResponse = Object.freeze({
  success: "success",
  error: "error",
  tokenError: "tokenError",
});
