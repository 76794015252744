import { io } from "socket.io-client";
import { BASE_URL } from "utils/constants";

//export const socket = socketio.connect(BASE_URL);
//export const SocketContext = React.createContext();

export const socket = io(BASE_URL, {
  withCredentials: true,
  extraHeaders: {
    "xscope-header": "xscope",
  },
  transports: ["websocket", "polling"], //"polling"
  //autoConnect: true,
  //reconnectionDelay: 500,
  //reconnection: true,
  //reconnectionAttempts: 10,
  forceNew: true,
});
