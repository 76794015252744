import { useQuery } from "@tanstack/react-query";
import ModalBuyPhotos from "components/ModalBuyPhotos";
import ContextAuth from "contexts/Auth.context";
import ContextSettings from "contexts/Settings.context";
import { createContext, useEffect, useState, useContext } from "react";
import app_photosService from "services/app_photosService";

const TotalPicturesContext = createContext();

const TotalPicturesContextProvider = ({ children }) => {
    const {isLoading, data, isError, isSuccess, refetch, isFetching} = useQuery({ queryKey: ['totalPhotos'], queryFn: app_photosService.getTotalPhotos, refetchOnWindowFocus: false })
    const [totalPictures, setTotalPictures] = useState(0);
    const [photosPurchased, setPhotosPurchased] = useState(0);
    const { subscribe } = ContextAuth();
    const { settings } = ContextSettings();

    const [openModal, setOpenModal] = useState(false);

    const openModalLimitPhotos = () => {
        setOpenModal(true)
    }

    const getMaxPhotos = () => {
        return subscribe.name === "Free" 
        ? (settings.limitPhotosFree) 
        : subscribe.period === "year" ? parseInt(settings.limitPhotosSingleU) * 12 : parseInt(settings.limitPhotosSingleU)
    }

    const add = (cant) => {
        try {
            if(!["Free", "Single user"].includes(subscribe.name)) return true

            const temp = totalPictures + cant
            const nMaxPhotos = getMaxPhotos()
            if(temp > (nMaxPhotos + photosPurchased)) {
                openModalLimitPhotos()
                throw new Error("Limit Photos")
            }
            setTotalPictures(totalPictures + cant)
            return true
        } catch (error) {
            console.error(error)
            return false
        }
    };

    const discount = (cant) =>{
        if(!["Free", "Single user"].includes(subscribe.name)) return 

        const temp = totalPictures - cant;
        if(temp > 0)  setTotalPictures(temp)
        else setTotalPictures(0)
    }

    useEffect(() => {
        if(isSuccess && data){
            console.log(data)
            setTotalPictures(data.total)
            setPhotosPurchased(data.photosPurchased)
        }
    }, [isSuccess,isFetching]);

    return (
        <TotalPicturesContext.Provider value={
            {
                add,
                discount,
                totalPictures,
                openModalLimitPhotos,
                photosPurchased
            }
        }>
            <ModalBuyPhotos key={openModal && Date.now()} photosAvailable={(getMaxPhotos() + photosPurchased) - totalPictures} photosPurchased={photosPurchased} refetchTotalPhotos={refetch} isOpen={openModal} handleClose={() => setOpenModal(!openModal)} />
            {children}
        </TotalPicturesContext.Provider>
    )
}


export { TotalPicturesContextProvider };

const ContextTotalPictures = () => {
    return useContext(TotalPicturesContext);
}

export default ContextTotalPictures;