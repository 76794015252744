import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { ChangeContext } from 'components/useContext/ChangeContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom'
import styles from './OutputDialog.style';

export const OutputDialog = (props) => {
    
    const {
        setIsChangedGeneralConditions,
        setIsChangedRoofComposition,
        setIsChangedOverviewPictures,
        setIsChangedRoofSlope,
        setIsChangedRoofDrainage,
        setIsChangedwallDetails,
        setIsChangedRoofDetails,
        setIsChangeProjectInformation,
        setChangeDrawing,
    } = useContext(ChangeContext);

    const { cancelDialogOpen, setCancelDialogOpen, subtitle, buttonConfirm, cancelButtonText } = props;
    const classes = styles();
    return (
        <Dialog
            className={classes.dialogCloseModal}
            open={cancelDialogOpen}
        >
            <DialogTitle disableTypography={true}>
                <div>Hey! you have not saved</div>
                <div></div>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    justifyContent="center"
                    style={{
                        marginTop: "-5px",
                        marginBottom: 42,
                    }}
                    className={classes.subtitle}
                >
                    {subtitle}
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            color="default"
                            className={classes.cancelButtonCloseModal}
                            onClick={() => {
                                setCancelDialogOpen(false)
                            }}
                        >
                            {cancelButtonText}
                        </Button>
                    </Grid>
                    {
                        (buttonConfirm) &&
                        <Grid item xs={12} sm={6}>
                            <Link to={`/Projects`} style={{ textDecoration: "none" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className={classes.submitButtonCloseModal}
                                    onClick={() => {
                                        setIsChangedGeneralConditions(false);
                                        setIsChangedRoofComposition(false);
                                        setIsChangedOverviewPictures(false);
                                        setIsChangedRoofSlope(false);
                                        setIsChangedRoofDrainage(false);
                                        setIsChangedwallDetails(false);
                                        setIsChangedRoofDetails(false);
                                        setIsChangeProjectInformation(false);
                                        setCancelDialogOpen(false);
                                        setChangeDrawing(false);
                                    }}
                                >
                                    Yes
                                </Button>
                            </Link>
                        </Grid>
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
