import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";
import app_creditsServices from "services/app_credits.services";

const CreditsContext = createContext();

const CreditsContextProvider = ({ children }) => {
    const { isLoading, data, isError, isSuccess, isFetching, refetch } = useQuery({
        queryKey: ['credits', "total"],
        queryFn: app_creditsServices.getTotal,
        refetchOnWindowFocus: false
    })

    const [total, setTotal] = useState(0);

    const addCredits = (amount) => {
        setTotal(total + parseInt(amount))
    }

    const discountCredits = (amount) => {
        const temp = total - parseInt(amount);
        if(temp >= 0) setTotal(temp)
        
    }

    useEffect(() => {
        if(isSuccess && data){
            setTotal(data)
        }
    }, [isSuccess,isFetching]);


    return (
        <CreditsContext.Provider value={
            {
                initLoading: isLoading ,
                loading: isFetching, 
                total,
                addCredits,
                discountCredits,
                refreshCredits: refetch
            }
        }>
            {children}
        </CreditsContext.Provider>
    )
}


export { CreditsContextProvider };

const ContextCredits = () => {
    return useContext(CreditsContext);
}

export default ContextCredits;
