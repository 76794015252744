import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
    dialogCloseModal: {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: 592,
        height: 680,
      },
      "& .MuiDialog-paper": {
        width: 592,
        height: 'auto',
      },
      "& .MuiDialogTitle-root": {
        fontFamily: 'Titillium Web',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '700',
        color: '#3B5D6E',
        padding: '32px 32px',
        textAlign: 'center',
        '&>div:nth-child(1)': {
          width: '100%'
        },
        '&>div:nth-child(2)': {
          borderBottom: '1px solid #E3EBF1',
          width: '100%',
          height: '20px',
        }
      },
      "& .MuiDialogContent-root": {
        padding: "0px 40px 32px 40px",
        boxSizing: 'border-box',
      },
    },
    subtitle: {
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Titillium Web',
      fontStyle: 'normal',
      color: '#3B5D6E'
    },
    cancelButtonCloseModal: {
      width: 176,
      height: 48,
      width: '100%',
      background: '#4E7D96',
      border: '2px solid #4E7D96',
      boxSizing: 'border-box',
      borderRadius: '40px',
      boxShadow: '0px 8px 24px -8px rgba(78, 125, 150, 0.24)',
      "& .MuiButton-label": {
        fontFamily: `'Titillium Web', sans-serif`,
        fontSize: 16,
        color: "white",
        fontWeight: 'bold',
        textAlign: "center",
        fontStyle: "normal",
      },
      "&:hover": {
        backgroundColor: '#395C6F'
      },
      "&:hover .MuiButton-label": {
  
      },
    },
    submitButtonCloseModal: {
      width: 176,
      height: 48,
      width: '100%',
      background: '#FF844B',
      border: '2px solid #FF844B',
      boxSizing: 'border-box',
      borderRadius: '40px',
      boxShadow: '0px 4px 8px rgba(255, 72, 0, 0.24)',
      "& .MuiButton-label": {
        fontFamily: `'Titillium Web', sans-serif`,
        fontSize: 16,
        color: "white",
        fontWeight: 'bold',
        textAlign: "center",
        fontStyle: "normal",
      },
      "&:hover": {
        backgroundColor: '#FF6D00'
      },
      "&:hover .MuiButton-label": {
  
      },
    },  
  }));
  
  export default styles;