import jwt from "jsonwebtoken";

export const verifyToken = function (token) {
  return new Promise((resolve, reject) => {
    jwt.verify(token, "RoofAPI", async (err, user) => {
      if (err) {
        reject(err);
      } else {
        resolve(user);
      }
    });
  });
};
