export const styles = (theme) => ({
    footer: {
        textAlign: 'center',
        '&>:nth-child(1)': {
            '&>:nth-child(1)': {
                width: 'auto',
                height: 74,
                backgroundColor: '#162C36',
                color: '#BECDD4',
                fontFamily: 'Titillium Web',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                '&>:nth-child(1)': {
                    paddingTop: 15,
                    paddingBottom: 3,
                },
                '&>:nth-child(2)': {
                    '&>:nth-child(1)': {
                        textDecoration: 'none',
                        color: '#BECDD4'
                    }
                }
            },
            '&>:nth-child(2)': {
                width: 'auto',
                height: 4,
                backgroundColor: '#FF844B',
            }
        },
    },
});