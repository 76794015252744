import { createContext, useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyToken } from "utils/token";
import userService from "services/UserService";
import { removeToken } from "redux/actions/token";
import { PHOTO_URL } from "utils/constants";
import SubscriptionService from "services/SubscriptionService";
import axios from "axios";

const AuthContext = createContext();

let callingSpecialCustomer = false;

const AuthContextProvider = ({ children }) => {
    const token = useSelector(state => state.token);
    const dispatch = useDispatch();
    const removeTokenAction = () => dispatch(removeToken());
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({
        id: 0,
        address: "",
        admin: null,
        admin_id: "",
        email: "",
        name: "",
        phone: "",
        profile_photo: "",
        role: "",
        user_id: "",
        customer_id: "",
        login: "email",
        device: null,
        have_additional_permissions: 0,
        planSelected: ""
    });
    const [subscribe, setSubscribe] = useState({
        product: "",
        members: 1,
        active: 1,
        status: "free",
        name: "Free",
        subscription_id: "",
        period: "month",
        period_end: 0,
        notify: "active",
    });// default plan free
    const [session, setSession] = useState(null);
    const [search, setSearch] = useState(null);
    const [projectName, setProjectName] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [userSelected, setUserSelected] = useState(null);
    const [newMessage, setNewMessage] = useState(null);
    const [lastMessageReceived, setLastMessageReceived] = useState(null);
    const [userId, setUserId] = useState(null);
    const [device, setDevice] = useState(null);
    const [sendingUser, setSendingUser] = useState("");

    const [dataUser, setDataUser] = useState(null);
    const [adminID, setAdminID] = useState("");


    const initDataLogin = async () => {
        if (!token) {
            setLoading(false)
            return
        }
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        setSession(null);
        await verifyToken(token)
            .then((user) => {
                setAdminID(user.adminId);
                setUserId(user.userId);
                setDevice(user.device);
                getDataUser(user.device);
            })
            .catch((err) => {
                setSession("Your session has expired");
                removeTokenAction();
                setUserId(null);
                setDevice(null);
                setLoading(false)
            })
    };

    const getDataUser = async (device) => {
        const res = await userService.getUserData()
            .catch((err) => {
                console.log(err);
            });

        if (res.status === "success") {
            if (res.data !== null) {
                const userObj = res.data;
                if (device === userObj.device) {
                    setUser(userObj);
                    setDataUser(userObj);

                    if (!userObj?.is_a_special_customer)
                        await getSubscription(userObj.admin_id);
                    else await getSubscriptionSpecialCustomer();
                    setProfilePhoto(PHOTO_URL + userObj.profile_photo);
                } else {
                    removeTokenAction();
                    if (user.login === "facebook") {
                        window.FB.api("/me/permissions", "delete", null, () =>
                            window.FB.logout()
                        );
                    }
                    setUserId(null);
                    setDevice(null);
                }
            } else {
                // se supone que nunca se llega aqui porque el primer if solo deja entrar a status success
                if (res.status === "failed-token") {
                    removeTokenAction();
                    if (user.login === "facebook") {
                        window.FB.api("/me/permissions", "delete", null, () =>
                            window.FB.logout()
                        );
                    }
                    setUserId(null);
                    setDevice(null);
                    //window.location.reload();
                }
            }
        }
        setLoading(false)
    };

    const getSubscription = async (userId) => {
        const res = await SubscriptionService.getSubscriptionInit(userId);
        if (res.status !== "success") return
        if (res.data !== null && res.data !== undefined) {
            setSubscribe(res.data);
            return
        }
    };

    const getSubscriptionSpecialCustomer = async () => {
        if (callingSpecialCustomer) return;
        callingSpecialCustomer = true;
        const res = await SubscriptionService.getSubscriptionInitSpecialCustomer();
        callingSpecialCustomer = false;
        if (res.status !== "success") return
        if (res.data !== null && res.data !== undefined) {
            setSubscribe(res.data);
            return
        }
    };


    useEffect(() => {
        initDataLogin();
    }, [token]);

    return (
        <AuthContext.Provider value={
            {
                loading,
                token,
                session,initDataLogin,
                user, subscribe, search, setSearch, projectName, profilePhoto, setNewMessage, setLastMessageReceived,
                setSession,userId,setUserId,device,setUserSelected, setProjectName, userSelected,
                newMessage, sendingUser, lastMessageReceived, setSendingUser,dataUser,adminID
            }
        }>
            {children}
        </AuthContext.Provider>
    )
}


export { AuthContextProvider };

const ContextAuth = () => {
    return useContext(AuthContext);
}

export default ContextAuth;

