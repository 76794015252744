import axios from "axios";
import { BASE_URL } from "utils/constants";

class NotifyService {
  async getNotify() {
    let result;
    const url = `${BASE_URL}/api/notify/get`;

    await axios
      .get(url)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getNotifyOld(data) {
    let result;
    const url = `${BASE_URL}/api/notify/old/get`;

    await axios
      .post(url, data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async updateNotify(data) {
    let result;
    const url = `${BASE_URL}/api/notify/update`;

    await axios
      .put(url, data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async updateNotificationList() {
    let result;
    const url = `${BASE_URL}/api/notify/update/notificationList`;

    await axios
      .put(url)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async userNotifications(data) {
    console.log('me estoy llamando help')
    let result;
    const url = `${BASE_URL}/api/notify/update/userNotifications`;

    await axios
      .put(url, data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
}

export default new NotifyService();
