import BigNumber from "bignumber.js";
import CompanyCampService from "services/CompanyCampService";
import Swal from "sweetalert2";
import urlRegex from "url-regex";
import * as turf from "@turf/turf";
import Geocode from "react-geocode";
import { v4 as uuidv4 } from "uuid";
import {
  GOOGLE_MAP_KEY_2,
  client_id_companycamp,
  drainageTypes,
  edges,
  maxSizeOfImage,
  maxSizeOfVideo,
  metalTerminationFlashingsTypes,
  uri_companycamp,
} from "./constants";
import { AZURE_IMAGE_URL_CROPED } from "./Azure/azure_credentials";

export function metersToFeetLabel(meters) {
  var totalInch = (meters * 100) / 2.54;
  var feet = Math.floor(totalInch / 12);
  var inch = Math.floor(totalInch - 12 * feet);
  var labelValue = `${feet}ft ${inch}in`;
  return labelValue;
}

export function metersToFeetCeiling(meters) {
  var feet = meters * 3.2808;
  return Math.ceil(feet);
}
export function sqmetersToSqft(sqmeters) {
  var sqft = sqmeters * 10.764;
  return Math.round(sqft);
}

export function metersToFeetRound(meters) {
  var feet = meters * 3.2808;
  return feet.toFixed(2);
}

export function metersToFeetCeilingWithoutSymbol(meters) {
  var feet = meters * 3.2808;
  return Math.ceil(feet);
}

export function getBounds(features, map) {
  const envelop = turf.envelope(features);
  const newevelop = turf.transformRotate(envelop, map.getBearing());
  //const scale = turf.transformScale(newevelop,-0.80)
  //const scale = turf.buffer(newevelop, 1.005);
  //let bbox = turf.bbox(turf.envelope(newevelop));
  let bbox = turf.bbox(newevelop);
  return bbox;
}

export function compress(imageSrc) {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imageSrc;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const MAX_WIDTH = 200;
      const MAX_HEIGHT = 150;
      var top = 0,
        left = 0;
      var width = img.width;
      var height = img.height;
      if (width > height) {
        if (width > MAX_WIDTH) {
          canvas.width = MAX_WIDTH;
          canvas.height = MAX_HEIGHT;
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
          top = (MAX_HEIGHT - height) / 2;
        } else {
          canvas.width = width;
          canvas.height = width;
          top = (width - height) / 2;
        }
      } else {
        if (height > MAX_HEIGHT) {
          canvas.width = MAX_WIDTH;
          canvas.height = MAX_HEIGHT;
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
          left = (MAX_WIDTH - width) / 2;
        } else {
          canvas.width = height;
          canvas.height = height;
          left = (height - width) / 2;
        }
      }
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, left, top, width, height);
      var newImageSrc = ctx.canvas.toDataURL("image/jpeg", 0.7);
      resolve(newImageSrc);
    };
  });
}

export const getColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export class CustomError extends Error {
  constructor(type = "default", ...params) {
    // Pasa los argumentos restantes (incluidos los específicos del proveedor) al constructor padre
    super(...params);

    // Mantiene un seguimiento adecuado de la pila para el lugar donde se lanzó nuestro error (solo disponible en V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }

    this.name = "CustomError";
    // Información de depuración personalizada
    this.type = type;
    this.date = new Date();
  }
}

export const importAll = (r) => {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

/**
 *
 * @param {array} data [1, 2, 3, 4, 5, 6]
 * @param {number} size 4
 * @returns {matriz} [ [1, 2, 3, 4], [5, 6] ]
 */
export const sliceArray = (data, size) => {
  let result = [];
  for (var i = 0; i < data.length; i += size) {
    const hasta = i + size;
    const tmp = hasta >= data.length ? data.slice(i) : data.slice(i, hasta);
    result.push(tmp);
  }
  return result;
};

export const convertImageToSize = (sizeImage = 0) => {
  const dividiendo = BigNumber(1024).multipliedBy(1024);
  const divisor = BigNumber(sizeImage);
  return Number(BigNumber(divisor).dividedBy(dividiendo).toNumber().toFixed(2));
};

export const convertSizeToKiloByte = (sizeImage = 0) => {
  const dividiendo = BigNumber(1024);
  const divisor = BigNumber(sizeImage);
  return `${BigNumber(divisor).dividedBy(dividiendo).toNumber().toFixed(3)}KB`;
};

export const convertSizeToMegaByte = (sizeImage = 0) => {
  const dividiendo = BigNumber(1024).multipliedBy(1024);
  const divisor = BigNumber(sizeImage);
  return `${BigNumber(divisor).dividedBy(dividiendo).toNumber().toFixed(2)}MB`;
};

export const convertSizeToKiloByteNumber = (sizeImage = 0) => {
  const dividiendo = BigNumber(1024);
  const divisor = BigNumber(sizeImage);
  return Number(BigNumber(divisor).dividedBy(dividiendo).toNumber().toFixed(3));
};

export const convertSizeToMegaByteNumber = (sizeImage = 0) => {
  const dividiendo = BigNumber(1024).multipliedBy(1024);
  const divisor = BigNumber(sizeImage);
  return Number(BigNumber(divisor).dividedBy(dividiendo).toNumber().toFixed(2));
};

export const getArrayImage = (arrayImages = []) => {
  let filesList = [];
  let fileListError = [];
  let error = false;
  for (const image of arrayImages) {
    if (
      !(
        convertImageToSize(image?.size) >
        convertSizeToMegaByteNumber(maxSizeOfImage)
      )
    ) {
      filesList.push(image);
    } else {
      error = true;
      convertSizeToKiloByteNumber(image?.size) < 1025
        ? fileListError.push(convertSizeToKiloByte(image?.size))
        : fileListError.push(convertSizeToMegaByte(image?.size));
    }
  }
  return { filesList, error, fileListError };
};

export const getVideo = (videos) => {
  let filesList = [];
  let fileListError = [];
  let error = false;
  for (const video of videos) {
    if (!(convertImageToSize(video?.size) > maxSizeOfVideo)) {
      filesList.push(video);
    } else {
      error = true;
      convertSizeToKiloByteNumber(video?.size) < 1025
        ? fileListError.push(convertSizeToKiloByte(video?.size))
        : fileListError.push(convertSizeToMegaByte(video?.size));
    }
  }
  return { filesList, error, fileListError };
};

export const convertImageToTable = (image, user_id, mID, date, createdAt) => {
  const auxImg = {};
  auxImg.photo_id = image.split(".")[0];
  auxImg.photo_name = image;
  auxImg.user_id = user_id;
  auxImg.project_id = mID;
  auxImg.comments = "0";
  auxImg.type = "photo";
  auxImg.created = date;
  auxImg.updated = date;
  auxImg.create_time = createdAt;
  auxImg.isCondition = "1";
  auxImg.imageSource = "overviewPictures";
  return;
};

export const openLoading = (message, title = "") => {
  Swal.fire({
    title: `${title}`,
    text: `${message}`,
    background: "#FFFFFF",
    color: "#3C5D6E",
    confirmButtonColor: "#FF844B",
    allowOutsideClick: false,
  });
  Swal.showLoading();
};

// Resize photo

const MAX_WIDTH = 800;
const QUALITY = 0.9;

const readPhoto = async (photo) => {
  const canvas = document.createElement("canvas");
  const img = document.createElement("img");

  img.src = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target.result);
    reader.readAsDataURL(photo);
  });
  await new Promise((resolve) => {
    img.onload = resolve;
  });

  canvas.width = img.width;
  canvas.height = img.height;
  canvas.getContext("2d").drawImage(img, 0, 0, canvas.width, canvas.height);

  return canvas;
};

const scaleCanvas = (canvas, scale) => {
  const scaledCanvas = document.createElement("canvas");
  scaledCanvas.width = canvas.width * scale;
  scaledCanvas.height = canvas.height * scale;

  scaledCanvas
    .getContext("2d")
    .drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);

  return scaledCanvas;
};

const optimizePhoto = async (photo, MAXWIDTH, QUA = QUALITY) => {
  let canvas = await readPhoto(photo);
  const max = MAXWIDTH ? MAXWIDTH : MAX_WIDTH;
  while (canvas.width >= 2 * max) {
    canvas = scaleCanvas(canvas, 0.5);
  }

  if (canvas.width > max) {
    canvas = scaleCanvas(canvas, max / canvas.width);
  }

  return new Promise((resolve) => {
    canvas.toBlob(resolve, "image/jpeg", QUA);
  });
};

export const PhotoToJpg = async (photo) => {
  let canvas = await readPhoto(photo);
  return new Promise((resolve) => {
    canvas.toBlob(resolve, "image/jpeg", 1);
  });
};

export default optimizePhoto;

//

export const getDataFromMap = (drawLayers) => {
  const values = {
    facets: [],
  };
  let indexArea = 0;
  let indexLength = 0;
  for (let layerKey in drawLayers) {
    for (let key in drawLayers[layerKey]["facets"]) {
      let edgeDes = [],
        counts = {},
        quotas = {};
      for (
        let edgeKey = 0;
        edgeKey < drawLayers[layerKey]["facets"][key].edgeKeys.length;
        edgeKey++
      ) {
        indexLength = indexLength + 1;
        let edgeId = drawLayers[layerKey]["facets"][key].edgeKeys[edgeKey];
        edgeDes.push({
          id: edgeId,
          name:
            drawLayers[layerKey]["edges"][edgeId].name !== ""
              ? drawLayers[layerKey]["edges"][edgeId].name
              : `Length ${indexLength}`,
          length_m: drawLayers[layerKey]["edges"][edgeId].length_m,
          coordinates: drawLayers[layerKey]["edges"][edgeId].coordinates,
          color: drawLayers[layerKey]["edges"][edgeId].color,
          length_ft: drawLayers[layerKey]["edges"][edgeId].length_ft,
          style: drawLayers[layerKey]["edges"][edgeId].style,
        });
      }
      indexLength = 0;
      for (let keyCount in drawLayers[layerKey]["counts"]) {
        if (drawLayers[layerKey]["counts"][keyCount]["facetKey"] === key)
          counts[keyCount] = drawLayers[layerKey]["counts"][keyCount];
      }
      for (let keyQuota in drawLayers[layerKey]["quotas"]) {
        if (drawLayers[layerKey]["quotas"][keyQuota]["facetKey"] === key)
          quotas[keyQuota] = drawLayers[layerKey]["quotas"][keyQuota];
      }
      indexArea = indexArea + 1;
      values["facets"].push({
        id: key,
        name:
          drawLayers[layerKey]["facets"][key].name !== ""
            ? drawLayers[layerKey]["facets"][key].name
            : `Area ${indexArea}`,
        sqft: drawLayers[layerKey]["facets"][key].sqft,
        pitch: drawLayers[layerKey]["facets"][key].pitch,
        sqft_pitch: drawLayers[layerKey]["facets"][key].sqft_pitch,
        edgeKeys: drawLayers[layerKey]["facets"][key].edgeKeys,
        edges: edgeDes,
        counts,
        quotas,
        coordinates: drawLayers[layerKey]["facets"][key].coordinates,
      });
    }
  }

  return values;
};

export const isURL = (str) => {
  return urlRegex({ exact: false }).test(str);
};

export function validateTypeFileImg(file) {
  try {
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    return allowedTypes.includes(file.type);
  } catch {
    return false;
  }
}

export function validateTypeFile(file) {
  try {
    const allowedTypes = ["csv", "rar", "zip", "docx", "pdf", "xlsx"];

    const fileType = file.name.split(".").pop();
    return allowedTypes.includes(fileType);
  } catch {
    return false;
  }
}

export function validateFileSize(file, size = 5) {
  try {
    const maxSize = size * 1024 * 1024; // 5 MB en bytes
    return file.size <= maxSize;
  } catch {
    return false;
  }
}

export function getFileFormat(file) {
  try {
    const fileName = file.name;
    const fileFormat = fileName.split(".").pop();
    return fileFormat;
  } catch {
    return false;
  }
}

export const returnValueOfAreaById = (areas = [], idArea = "") => {
  try {
    return areas.find((item) => item.id === idArea)?.value || "";
  } catch (err) {
    console.log(err);
    return "";
  }
};

export const returnIdOfAreaByValue = (areas = [], valueArea = "") => {
  try {
    return areas.find((item) => item.value === valueArea).id || "";
  } catch (err) {
    console.log(err);
    return "";
  }
};

export const getLengthsFromObject = (mapInfo = {}, key = "") => {
  const defaultValue = [{ id: "Select", value: "Select" }];
  try {
    if (key === "" || key === "Select") return defaultValue;
    return [{ id: "Select", value: "Select" }, ...mapInfo[key]];
  } catch (err) {
    return defaultValue;
  }
};

export const getObjectLengthId = (mapInfo = {}, areaName = "", value = "") => {
  try {
    return mapInfo[areaName].find((item) => item.value === value)?.id;
  } catch (err) {
    console.log(err);
  }
};

export const getActualLength = (value = "", areaName = "", mapInfo = {}) => {
  try {
    return value === "Select"
      ? "Select"
      : mapInfo[areaName].find((item) => item.id === value)?.value;
  } catch (err) {
    return "Select";
  }
};

export const fIsInProcessOrDone = (event, state) => {
  state && event.preventDefault();
};

export const fIsInProcessOrDoneDiv = (state) => {
  return state ? "none" : "auto";
};

export const getLengthsFromObjectAccordingToDetail = (
  mapInfo = {},
  key = "",
  keyDetail = ""
) => {
  const { addLength } = metalTerminationFlashingsTypes[keyDetail];
  if (!addLength) {
    return [{ id: "", value: "" }];
  }
  const defaultValue = [{ id: "Select", value: "Select" }];
  try {
    if (key === "" || key === "Select") return defaultValue;
    return [{ id: "Select", value: "Select" }, ...mapInfo[key]];
  } catch (err) {
    return defaultValue;
  }
};

export function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    // mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: "image/jpeg" });
}

export const validateToken = async (ls_token_companyCamp) => {
  try {
    const { created_at, expires_in, refresh_token } = ls_token_companyCamp;
    const currentDate = Math.floor(new Date().getTime() / 1000);
    const isValid = currentDate < created_at + expires_in;
    if (isValid) return undefined;
    const response = await CompanyCampService.refreshToken(refresh_token);
    const { status, data } = response;
    return status ? data : null;
  } catch (err) {
    return null;
  }
};

export const redirectToCompanyCam = () => {
  window.location.href = `https://app.companycam.com/oauth/authorize?client_id=${client_id_companycamp}&redirect_uri=${uri_companycamp}&response_type=code&scope=read+write+destroy`;
};

export function localeNumber(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const getColorHexFromRGB = (color) => {
  if (!color.includes("rgb")) return color;

  function ColorToHex(c) {
    var hexadecimal = c.toString(16);
    return hexadecimal.length === 1 ? "0" + hexadecimal : hexadecimal;
  }

  function ConvertRGBtoHex(red, green, blue) {
    return "#" + ColorToHex(red) + ColorToHex(green) + ColorToHex(blue);
  }

  let hex = color.slice(4, color.length - 1);
  hex = hex.replaceAll(",", "");
  hex = hex.split(" ");

  return ConvertRGBtoHex(parseInt(hex[0]), parseInt(hex[1]), parseInt(hex[2]));
};

export const getColorByEdge = (name) => {
  //const arr = Object.values(drawLayers.A.edges);
  const arr = Object.values(edges);
  //const edge = arr.find(ed => ed.edgeKey.toLowerCase().replace('_', ' ') === name.toLowerCase())
  const edge = arr.find(
    (ed) => ed.name.toLowerCase() === name.toLowerCase().replace("_", " ")
  );
  //console.log('edge', edge)
  return edge.color;
};

export const newCompanyCampImageFormat = (array = []) => {
  return array.reduce(
    (acc, url) => {
      const newId = `${uuidv4()}.${getFileExtension(clearNameFromUrl(url))}`;
      acc.mixedArray.push({ id: clearNameFromUrl(url), newUrl: newId });
      acc.newArray.push(newId);
      acc.originalArray.push(clearNameFromUrl(url));
      return acc;
    },
    { originalArray: [], newArray: [], mixedArray: [] }
  );
};

export const clearNameFromUrl = (stringName) => {
  let string = stringName;
  try {
    let firstIndex = string.indexOf("?");
    string = firstIndex === -1 ? string : string.substring(0, firstIndex);
  } catch (err) {
    console.log(err);
  }
  return string;
};

export const getFileExtension = (filename = "") => {
  const parts = filename.split(".");
  return parts[parts.length - 1];
};

export const closeMessageBox = () => {
  Swal.close();
};

export const messageBox = (
  icon,
  title,
  text,
  allowOutsideClick = false,
  didClose = () => {}
) => {
  Swal.fire({ icon, title, text, allowOutsideClick, didClose: didClose() });
};

export const isNullOrEmpty = (value) => {
  return value === undefined ||
    value === null ||
    value === "" ||
    value === "undefined" ||
    value === "null"
    ? true
    : false;
};

export const isObjectNullOrEmpty = (obj) => {
  if (obj === null || obj === undefined) {
    return true;
  }
  const entries = Object.entries(obj);
  return entries.length === 0;
};

export function formatNumber(number, fixed = 1) {
  try {
    var roundNumber = number.toFixed(fixed);
    var formatNumber = roundNumber.toString();

    return formatNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (error) {
    return "0";
  }
}

export const getAdressFromCoordinates = (latitud, longitud) => {
  return new Promise((resolve, reject) => {
    Geocode.setApiKey(
      GOOGLE_MAP_KEY_2 /*"AIzaSyAoJ3DN6v7oJkdJEOYRkbbUvUmcaygGxKA"*/
    );
    Geocode.setRegion("es");
    Geocode.fromLatLng(latitud, longitud)
      .then((response) => {
        if (response.results && response.results.length > 0) {
          const result = response.results[0];
          const direccion = result.formatted_address;
          let ciudad = "";
          let estado = "";

          for (let i = 0; i < result.address_components.length; i++) {
            const addressComponent = result.address_components[i];
            const types = addressComponent.types;

            if (types.includes("locality")) {
              ciudad = addressComponent.long_name;
            }

            if (types.includes("administrative_area_level_1")) {
              estado = addressComponent.short_name;
            }
          }

          const resultado = {
            address: direccion,
            city: ciudad,
            state: estado,
          };

          resolve(resultado);
        } else {
          reject("No se encontraron resultados.");
        }
      })
      .catch((error) => {
        reject("Error en la geocodificación: " + error.message);
      });
  });
};

export const countOptions = () => {
  const roofDetails = {
    label: "Roof Details",
    options: Object.keys(metalTerminationFlashingsTypes)
      .reduce((acc, key) => {
        if (metalTerminationFlashingsTypes[key].addCount) {
          acc.push({ label: key, value: key });
        }
        return acc;
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label)),
  };
  const options = Object.entries(drainageTypes).reduce(
    (acc, [label, { types, addCount }]) => {
      if (!addCount) return acc;
      if (types.length > 0) {
        const typeOptions = types.map((type) => ({
          label: type,
          value: type,
        }));

        acc.push({
          label,
          options: typeOptions,
        });
      } else {
        acc.push({
          label,
          options: [{ label, value: label }],
        });
      }

      return acc;
    },
    []
  );
  options.push(roofDetails);
  return options;
};

export const getValueOrDefault = (value, defaultValue) => {
  return value === undefined || value === null || value === ""
    ? defaultValue
    : value;
};

export const formattedTime = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};

export const formattedTime24Hours = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year} ${formattedTime(dateString)}`;
};

export const formatDateShort = (dateString) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  return `${month}/${day}/${year} ${formattedTime24Hours(dateString)}`;
};

export function isValidBase64URL(input) {
  return /^data:image\/(png|jpg|jpeg|gif);base64,/.test(input);
}

export const AdaptUrlPicturoToCroped = (
  url,
  proyect_id,
  size = "thumbnail"
) => {
  try {
    if (isValidBase64URL(url)) return url;
    const imageID = url?.substring(url?.lastIndexOf("/") + 1).split(".")[0];

    return `${AZURE_IMAGE_URL_CROPED}/${proyect_id}/${imageID.replace(
      ".jpg",
      ""
    )}-${size}.jpg`;
  } catch (error) {
    console.error(error);
    return url;
  }
};

export const setCRMTokenValue = (value) => {
  localStorage.setItem("CRMToken", value);
};

export const getCRMTokenValue = () => {
  return localStorage.getItem("CRMToken");
};
