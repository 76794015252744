import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";
import app_settingsServices from "services/app_settings.services";

const SettingsContext = createContext();

const SettingsContextProvider = ({ children }) => {
    const { isLoading, data, isError, isSuccess, isFetching } = useQuery({
        queryKey: ['settings'],
        queryFn: app_settingsServices.getAll,
        refetchOnWindowFocus: false
    })

    const [settings, setSettings] = useState({
        p_credits_rd_free: 25,
        p_credits_rd_su: 10,
        p_credits_rf_free: 20,
        p_credits_rf_su: 15,
        limitPhotosFree: 30,
        limitPhotosSingleU: 60,
        limitProjectsFree: 30,
        limitProjectsSingleU: 60,
        p_credits_photo_free: 25,
        p_credits_photo_su: 10,

        p_credits_drw_free: 100
    });

    useEffect(() => {
        if(isSuccess && data){
            setSettings({
                p_credits_rd_free: data.p_credits_rd_free || 25,
                p_credits_rd_su: data.p_credits_rd_su || 10,
                p_credits_rf_free: data.p_credits_rf_free || 20,
                p_credits_rf_su: data.p_credits_rf_su || 15,
                limitPhotosFree: data.limitPhotosFree || 30,
                limitPhotosSingleU: data.limitPhotosSingleU || 60,
                limitProjectsFree: data.limitProjectsFree || 30,
                limitProjectsSingleU: data.limitProjectsSingleU || 60,
                p_credits_photo_free: data.p_credits_photo_free || 25,
                p_credits_photo_su: data.p_credits_photo_su || 10,
                
                p_credits_drw_free: data.p_credits_drw_free || 100,
            })
        }
    }, [isSuccess,isFetching]);


    return (
        <SettingsContext.Provider value={
            {
                settings,isLoading
            }
        }>
            {children}
        </SettingsContext.Provider>
    )
}


export { SettingsContextProvider };

const ContextSettings = () => {
    return useContext(SettingsContext);
}

export default ContextSettings;
