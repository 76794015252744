import { Badge, List, ListItem, ListItemText, Menu, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import NotifyService from "services/NotifyService";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { socket } from "utils/socket";
import notification from 'assets/sound/notification.mp3'
import { Howl } from 'howler';
import { urlCalledFrom } from 'utils/constants';
import { ChangeContext } from 'components/useContext/ChangeContext';
import { VerifiedUser, VerifiedUserOutlined } from '@material-ui/icons';

const ITEM_HEIGHT = 48;

const Notifications = (props) => {
    const { setSendingUser, classes, fromWhereItIsInvoked } = props;
    const {
        count,
        setCount,
        setLastMessageReceived,
    } = useContext(ChangeContext);
    const [notifications, setNotifications] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    // const [count, setCount] = useState(0);
    const navigate = useNavigate();
    const [playSound, setPlaySound] = useState(false);

    const onStream = () => {
        socket.connect();

        socket.on("notifications", (data) => {
            if (data.notify) {
                setPlaySound(true);
                getNotifications();
            }
        });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNotify = async (item) => {
        setAnchorEl(null);
        if (item.type_notify === "mention" && fromWhereItIsInvoked === 'header') {
            navigate(`/Edit/Project/${item.projectId}/${item.ref_id}`);
        } else if (item.type_notify === "mention" && fromWhereItIsInvoked === 'detailProject') {
            // window.location.href = `/Edit/Project/${item.projectId}/${item.ref_id}`;
            navigate(`/Edit/Project/${item.projectId}/${item.ref_id}`);
            navigate(0);
        } else if (item.type_notify === "calendar") {
            navigate('/Calendar');
            navigate(0);
        }else if (item.type_notify === "update") {
            navigate('/Update/List');
        } else if (item.is_group) {
            setSendingUser(item?.group_user_id);
            navigate(`/Chat/Messenger`);
        } else {
            setSendingUser(item?.senderId);
            navigate(`/Chat/Messenger`);
        }
        const data = {
            id: item.id,
            readAll: false,
        };
        const res = await NotifyService.updateNotify(data);
        if (res.status === "success") {
            getNotifications();
        }
    };

    const getNotifications = async () => {
        const res = await NotifyService.getNotify();
        if (res.status === "success") {
            // // (urlCalledFrom.calledFromChat !== window.location.pathname) && setCount(res.data.count);
            // if (urlCalledFrom.calledFromChat !== window.location.pathname) {
            //     setCount(res.data.count);                
            // } else {
            //     updateNotificationList();
            // }
            try {
                const lastNotify = res?.data?.notifications?.find((msg) => msg.type_notify === 'message');
                setLastMessageReceived(lastNotify);
            } catch (err) {
                console.log(err);
            }
            setCount(res?.data?.count);
            setNotifications(res?.data?.notifications);
        } else {
            setNotifications([]);
        }
    };

    const handleClick = (event) => {
        if (notifications.length > 0) {
            setAnchorEl(event.currentTarget);
        }
    };

    const soundPlay = (src) => {
        const sound = new Howl({
            src,
            html5: true,
        });
        sound.play();
    }

    const updateNotificationList = async () => {
        try {
            await NotifyService.updateNotificationList();
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getNotifications();
        onStream();
    }, []);

    useEffect(() => {
        if (playSound) {
            soundPlay(notification);
            setPlaySound(false);
        }
    }, [playSound]);

    useEffect(() => {
        if (Boolean(anchorEl) && count > 0) {
            setCount(0);
            updateNotificationList();
        }
    }, [anchorEl]);

    return (
        <>
            <Badge badgeContent={count} style={{marginTop: 0}} color="error">
                <NotificationsNoneIcon
                    style={{ color: "white", cursor: "pointer" }}
                    aria-controls="notify"
                    aria-haspopup="true"
                    onClick={handleClick}
                />
            </Badge>
            <Menu
                id="notify"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                style={{
                    textDecoration: "none",
                }}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 6,
                        width: '30ch',
                    },
                }}
            >
                <List className={classes.root}>
                    {notifications.length > 0
                        ? notifications.map((item, index) => (
                            <ListItem
                                key={index}
                                alignItems="flex-start"
                                onClick={() => handleNotify(item)}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                        item.read_notify === 0 ? "#ECF0F1" : "#fff",
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            style={{
                                                color:
                                                    item.read_notify === 0 ? "#0B45D0" : "#3B5D6E",
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center"
                                            }}
                                        >
                                            {
                                                item.type_notify === "update" 
                                                ?  <>{item.sender}<VerifiedUser style={{width: 15}} /> </>
                                                :  <>{item.sender}</>
                                            }
                                        </Typography>
                                    }
                                    color=""
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                style={{
                                                    color: "#3B5D6E",
                                                }}
                                            >
                                                {item.projectName}
                                            </Typography>
                                            {` — ${item.message}`}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        ))
                        : null}
                </List>
            </Menu>
        </>
    )
}

export default Notifications