import ReactGA from "react-ga";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import store from "./redux/store";
import "./index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
//import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en";
import es from "javascript-time-ago/locale/es";
import { googleAnalyticsKey } from "utils/constants";
import { UserContextProvider } from "components/useContext/UserContext";
import { AppAccessContextProvider } from "components/useContext/AppAccess.context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthContextProvider } from "contexts/Auth.context";
import RootRouter from "router/root.router";
import mapboxgl from "mapbox-gl";
import { customTheme } from "./theme";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const queryClient = new QueryClient();
ReactGA.initialize(googleAnalyticsKey);

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(en);

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AuthContextProvider>
          {/*<UserContextProvider>*/}
          <AppAccessContextProvider>
            <ThemeProvider theme={customTheme}>
              <RootRouter />
              {/*<App />*/}
            </ThemeProvider>
          </AppAccessContextProvider>
          {/*</UserContextProvider>*/}
        </AuthContextProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
