import React from 'react';
import { Navigate, useRoutes } from "react-router-dom";
import ReactSuspenseContainer from "containers/ReactSuspense.container";
import { useDispatch } from 'react-redux';
import { removeToken } from 'redux/actions/token';
import ContextAuth from 'contexts/Auth.context';
const LazyLogin = React.lazy(() => import("components/Login"));
const LazySignup = React.lazy(() => import("components/Signup"));
const LazyEmailVerify = React.lazy(() => import("components/EmailVerify"));
const LazyForgotPassword = React.lazy(() => import("components/ForgotPassword"));
const LazyTerms = React.lazy(() => import("components/terms/TermsCond"));

const AuthRouter = () => {
  const { session, initDataLogin } = ContextAuth();
  const dispatch = useDispatch();
  const removeTokenAction = () => dispatch(removeToken());

  const routes = [
    {
      path: "/Login",
      element: <ReactSuspenseContainer>
        <LazyLogin session={session} changeUser={initDataLogin} />
      </ReactSuspenseContainer>,
    },
    {
      path: "/Signup",
      element: <ReactSuspenseContainer>
        <LazySignup />
      </ReactSuspenseContainer>,
    },
    {
      path: "/Email-Verify/:tokenUser",
      element: <ReactSuspenseContainer>
        <LazyEmailVerify removeToken={removeTokenAction} changeUser={initDataLogin} />
      </ReactSuspenseContainer>,
    },
    {
      path: "/Forgot-Password",
      element: <ReactSuspenseContainer>
        <LazyForgotPassword />
      </ReactSuspenseContainer>,
    },
    {
      path: "/Terms",
      element: <ReactSuspenseContainer>
        <LazyTerms />
      </ReactSuspenseContainer>,
    },
    {
      path: "*",
      element: <Navigate to="/Login" />,
    },
  ];

  return useRoutes(routes);
}

export default AuthRouter;