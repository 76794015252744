import { validateIcon } from "components/Edit/component/utils/iconValidate";
import { createContext, useContext, useEffect, useState } from "react";
import app_countsService from "services/app_countsService";
import Swal from "sweetalert2";
import { updateGuidlinesImageAzure } from "utils/Azure/azure";
import { AZURE_GUIDLINES_IMAGE_URL } from "utils/Azure/azure_credentials";
const MapCountsContext = createContext(null);

const MapCountsProvider = ({ children }) => {
    const [dataCounts, setDataCounts] = useState([]);
    const [loading, setLoading] = useState(false);

    async function convertImageUrlToImageBitmap(imageUrl) {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const imageBitmap = await createImageBitmap(blob);
            return imageBitmap;
        } catch (error) {
            console.error(error);
            return null
        }
    }

    const compareData = async () => {
        if(loading) return //la peticion inicial tiene que haber terminado
        setLoading(true)
        const resp = await app_countsService.getMyCounts().catch((e) => console.error(e))
        if (resp.status !== "success") return
        if (resp.data.length !== dataCounts.length) {
            // agrega nuevos iconos
            const fil = resp.data.filter(x => {
                const resp = dataCounts.findIndex((item) => item.value === x.order_icons)
                if (resp === -1) {
                    return x
                }
            });
            const promises = fil.map((icon) => adapEPtoState(icon, resp.myid));
            const result = await Promise.all(promises);
            setDataCounts([...result,...dataCounts]);
        } else {
            // cambia iconos si es que se modifico uno
            if(dataCounts.length <= 0) return
            const tempData = [...dataCounts]
            const promises = []
            resp.data.map((x) => {
                const index = dataCounts.findIndex((item) => item.value === x.order_icons && item.title !== x.name.replaceAll("_", " "))
                if (index > -1) {
                    promises.push(new Promise ((res,rej) => {
                        res(adapEPtoState(resp.data[index], resp.myid)
                            .then( (res) => tempData[index] = res  ))
                    }))
                }
            });
            await Promise.all(promises);
            setDataCounts(tempData);

        }

        setLoading(false)
    }

    const adapEPtoState = async (icon, myid) => {
        const src = `${AZURE_GUIDLINES_IMAGE_URL}/icons/${icon.src}?t=${new Date().getTime()}`
        const imgBitMap = await convertImageUrlToImageBitmap(src).catch((e) => console.error(e))
        const text = icon.name.replaceAll("_", " ")

        return {
            value: icon.order_icons, //order 
            nameRef: icon.name_ref, // 
            src: src,
            title: text,
            bitmap: imgBitMap,
            id: icon.admin_id ? icon.admin_id : myid
        };
    }

    const getCounts = async () => {
        setLoading(true)
        const resp = await app_countsService.getMyCounts().catch((e) => console.error(e))
        if (resp.status !== "success") return
        const { data, myid } = resp;
        const promises = data.map((icon) => adapEPtoState(icon, myid));

        const result = await Promise.all(promises);
        setDataCounts(result);
        setLoading(false)
    }

    const resetIcon = (order) => {
        app_countsService.resetCount(order)
            .then((resp) => {
                if (resp.status === "success") {
                    getCounts();
                }
            })
            .catch(err => console.error(err))
    }

    const addNewIcon = async (formAddIcon, cb) => {
        if (formAddIcon.name.length <= 1) {
            console.error("Icon name is invalid")
            return;
        }
        if (formAddIcon.icon) {
            const resp = await validateIcon(formAddIcon.icon).catch(err => console.log(err));
            if (!resp) {
                console.error("Icon file is invalid")
                return;
            }
        }
        Swal.fire({
            title: 'Add Icon...',
            background: '#FFFFFF',
            color: '#3C5D6E',
            confirmButtonColor: '#FF844B',
            allowOutsideClick: false,
            timer: 5000
        });
        Swal.showLoading();
        updateGuidlinesImageAzure(
            new File([formAddIcon.icon], `${formAddIcon.name}.png`, {
                type: "image/png",
                lastModified: formAddIcon.icon.lastModified,
            }),
            "icons/" + formAddIcon.id
        )
            .then(() => app_countsService.addNewIcon({
                name: formAddIcon.name,
                src: `${formAddIcon.id}/${formAddIcon.name}.png`,
            })
            )
            .then((resp) => {
                if (resp.status === "success") {
                    cb({
                        nameRef: formAddIcon.name, // 
                        title: formAddIcon.name
                    })
                    getCounts();
                }
            })
            .catch(err => console.error(err))
    }

    const uploadIcon = async (formUpdateIcon) => {
        if (formUpdateIcon.name.length <= 1) {
            console.error("Icon name is invalid")
            return;
        }
        if (formUpdateIcon.icon) {
            const resp = await validateIcon(formUpdateIcon.icon).catch(err => console.log(err));
            if (!resp) {
                console.error("Icon file is invalid")
                return;
            }
        }
        Swal.fire({
            title: 'Update Icons...',
            background: '#FFFFFF',
            color: '#3C5D6E',
            confirmButtonColor: '#FF844B',
            allowOutsideClick: false,
            timer: 5000
        });
        Swal.showLoading();
        if (!formUpdateIcon.icon) {
            app_countsService.addCount({
                order: formUpdateIcon.order,
                name: formUpdateIcon.name,
                //src: `default/${formUpdateIcon.nameRef}.png`,
                ref: formUpdateIcon.nameRef,
            })
                .then((resp) => {
                    if (resp.status === "success") {
                        getCounts();
                    }
                })
                .catch(err => console.error(err))
                .finally(() => Swal.close())
        } else {
            updateGuidlinesImageAzure(
                new File([formUpdateIcon.icon], `${formUpdateIcon.nameRef}.png`, {
                    type: "image/png",
                    lastModified: formUpdateIcon.icon.lastModified,
                }),
                "icons/" + formUpdateIcon.id
            )
                .then(() => app_countsService.addCount({
                    order: formUpdateIcon.order,
                    name: formUpdateIcon.name,
                    src: `${formUpdateIcon.id}/${formUpdateIcon.nameRef}.png`,
                    ref: formUpdateIcon.nameRef,
                })
                )
                .then((resp) => {
                    if (resp.status === "success") {
                        getCounts();
                    }
                })
                .catch(err => console.error(err))
        }
    }

    useEffect(() => {
        getCounts()
    }, []);


    return (
        <MapCountsContext.Provider
            value={{
                dataCounts,
                refetchCounts: compareData,
                resetIcon,
                addNewIcon,
                uploadIcon,
                loadingCounts: loading
            }}
        >
            {children}
        </MapCountsContext.Provider>
    );
}

export { MapCountsProvider };

const ContextMapCounts = () => {
    return useContext(MapCountsContext);
}

export default ContextMapCounts;