import headerBack from "assets/background/header.png";

const styles = (theme) => ({
  container: {
    position: "fixed",
    width: "100%",
    height: "100%",
    padding: 0,
    boxSizing: "border-box",
    background: "#F1F6FA",
    overflowX: "hidden",
    overflowY: "auto",
  },
  header: {
    backgroundImage: `url(${headerBack})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    padding: "0px 110.44px 0px 104px",
    maxHeight: "80px",
    height: "80px",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  accountBoxLeft: {
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    "&>:nth-child(2)": {
      marginRight: 19,
      marginLeft: 19,
    },
    "&>:nth-child(3)": {
      height: 24,
      marginTop: "10px",
    },
  },
  accountBox: {
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    fontSize: 12,
    color: "#fff",
    fontStyle: "normal",
    cursor: "pointer",
    "&>:nth-child(1)": {
      margin: "0 8px 0 16px",
      border: "1px solid #FFFFFF",
    },
    "&>:nth-child(2)": {
      "&>:nth-child(1)": {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
      },
      "&>:nth-child(2)": {
        marginTop: 2,
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "10px",
      },
    },
    "&>:nth-child(3)": {
      marginTop: "-15px",
    },
    backgroundColor: "#FF844B",
  },
  logo: {
    width: 100,
    height: 32,
    marginRight: 30,
  },
  search: {
    position: "relative",
    width: "280px",
    maxWidth: 550,
  },
  searchIcon: {
    position: "absolute",
    display: "flex",
    marginLeft: 19,
    height: "100%",
    alignItems: "center",
    color: "#FF844B",
    pointerEvents: "none",
    zIndex: 100,
    [theme.breakpoints.down("xs")]: {
      color: "#767786",
    },
  },
  inputRoot: {
    fontFamily: "Titillium Web",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    width: "100%",
    color: "#3B5D6E",
  },
  inputInput: {
    width: "100%",
    height: 40,
    padding: 0,
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,
    paddingRight: 19,
    border: "none",
    borderRadius: 56,
    backgroundColor: "#FFFFFF",
    "&:focus": {
      //border:'1px solid #7f99ff'
    },
    [theme.breakpoints.down("xs")]: {
      border: "1px solid #767786",
      backgroundColor: "#fff",
    },
  },

  // accountBox: {
  //   display: "flex",
  //   alignItems: "center",
  //   fontSize: 12,
  //   color: "#fff",
  //   cursor: "pointer",
  // },

  menuItemDesc: {
    fontFamily: "Titillium Web",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    color: "#3B5D6E",
  },

  content: {
    padding: "16px 24px",
  },
  createButton: {
    width: 176,
    height: 40,
    marginRight: 10,
    borderRadius: 40,
    backgroundColor: "#FF844B",
    boxShadow: "0px 4px 8px rgba(255, 72, 0, 0.24)",
    border: "none",
    "& .MuiButton-label": {
      fontFamily: "Titillium Web",
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "&:hover": {
      backgroundColor: "#FF6D00",
    },
    "&:hover .MuiButton-label": {
      //color:'#8c8c8c',
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: 0,
    },
  },

  projectBox: {
    border: "1px solid #767786",
    borderRadius: 8,
    maxWidth: 330,
    userSelect: "none",
    "& .MuiAvatar-root": {
      width: "100%",
      height: 200,
      borderRadius: 0,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    "& .MuiAvatar-img": {
      "-webkit-transition": "all .5s ease",
      "-moz-transition": "all .5s ease",
      "-ms-transition": "all .5s ease",
      transition: "all .5s ease",
      filter: "brightness(100%)",
    },
    "& .MuiAvatar-img:hover": {
      cursor: "pointer",
      transform: "scale(1.1)",
      "-webkit-transform": "scale(1.1)",
      "-moz-transform": "scale(1.1)",
      "-ms-transform": "scale(1.1)",
      filter: "brightness(110%)",
    },
  },
  projectList: {
    marginBottom: 40,
    userSelect: "none",
    "& .MuiListItem-root:hover": {
      borderRadius: 8,
      textDecoration: "none",
      backgroundColor: "#f1f1f3",
    },
    "& .MuiAvatar-root": {
      width: 48,
      height: 48,
      borderRadius: 8,
      cursor: "pointer",
      "-webkit-transition": "all .5s ease",
      "-moz-transition": "all .5s ease",
      "-ms-transition": "all .5s ease",
      transition: "all .5s ease",
    },
    "& .MuiAvatar-root:hover": {
      zIndex: 100,
      transform: "scale(1.2)",
      "-webkit-transform": "scale(1.2)",
      "-moz-transform": "scale(1.2)",
      "-ms-transform": "scale(1.2)",
    },
  },
  listHeader: {
    padding: "16px 20px",
    color: "#767786",
    fontSize: 14,
    fontWeight: 500,
    fontStryle: "normal",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
  },
  projectName: {
    color: "#fff",
    fontSize: 16,
    fontWeight: 500,
    fontStryle: "normal",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  lastViewed: {
    color: "#767786",
    fontSize: 16,
    fontWeight: 400,
    fontStryle: "normal",
  },
  pagination: {
    "& .MuiPaginationItem-root": {
      color: "#767786",
    },
    "& .MuiPaginationItem-textPrimary.Mui-selected": {
      color: "#fff",
    },
  },
  dialogShare: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 684,
    },
    "& .MuiDialog-paper": {
      width: 684,
    },
    "& .MuiDialogTitle-root": {
      padding: 15,
      borderBottom: "1px solid #76778633",
      fontSize: 20,
      fontWeight: 500,
      fontStyle: "normal",
      color: "#171d29",
    },
    "& .MuiDialogContent-root": {
      padding: "24px 32px",
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiDialog-paperWidthSm": {
        width: "100%",
      },
      "& .MuiDialogContent-root": {
        padding: 20,
      },
    },
  },
  deleteDialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 684,
    },
    "& .MuiDialog-paper": {
      width: 684,
    },
    "& .MuiDialogTitle-root": {
      padding: 32,
      borderBottom: "1px solid #76778633",
      fontSize: 24,
      fontWeight: 500,
      fontStyle: "normal",
      color: "#171d29",
    },
    "& .MuiDialogContent-root": {
      padding: "24px 80px",
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiDialog-paperWidthSm": {
        width: "100%",
      },
      "& .MuiDialogContent-root": {
        padding: 20,
      },
    },
  },

  // textBox: {
  //   marginBottom: 24,
  //   "& .MuiInputBase-root": {
  //     width: "auto",
  //     height: 56,
  //     borderRadius: 6,
  //     backgroundColor: "#fff",
  //   },
  //   "& .MuiOutlinedInput-input": {
  //     padding: "19px 16px",
  //     fontFamily: "Roboto",
  //     fontSize: 16,
  //     color: "#171d29",
  //     fontWeight: 400,
  //     fontStyle: "normal",
  //   },
  //   "& .MuiOutlinedInput-notchedOutline": {
  //     borderColor: "#767786",
  //   },
  // },
  // submitButton: {
  //   width: "100%",
  //   height: 56,
  //   borderRadius: 16,
  //   "& span": {
  //     fontFamily: "Roboto",
  //     fontSize: 18,
  //     fontWeight: 500,
  //     textAlign: "center",
  //     color: "#fff",
  //     fontStyle: "normal",
  //   },
  // },
  // cancelButton: {
  //   width: "100%",
  //   height: 56,
  //   borderRadius: 16,
  //   border: "1px solid #767786",
  //   "& span": {
  //     fontFamily: "Roboto",
  //     fontSize: 18,
  //     fontWeight: 500,
  //     textAlign: "center",
  //     color: "#767786",
  //     fontStyle: "normal",
  //   },
  // },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(2),
    color: theme.palette.grey[800],
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  // Style - Modal create new project

  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 592,
      height: 680,
    },
    "& .MuiDialog-paper": {
      width: 592,
      height: "auto",
    },
    "& .MuiDialogTitle-root": {
      fontFamily: "Titillium Web",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "700",
      color: "#3B5D6E",
      padding: "32px 32px",
      textAlign: "center",
      "&>div:nth-child(1)": {
        width: "100%",
      },
      "&>div:nth-child(2)": {
        borderBottom: "1px solid #E3EBF1",
        width: "100%",
        height: "20px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "0px 40px 32px 40px",
      boxSizing: "border-box",
    },
  },
  sectionTitle: {
    fontFamily: "Titillium Web",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    marginBottom: 24,
    marginLeft: 20,
  },
  textBox: {
    marginBottom: 16,
    "& .MuiInputBase-root": {
      width: "auto",
      height: 48,
      borderRadius: 72,
      backgroundColor: "#F1F6FA",
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        background: "none",
      },
    },
    "& .MuiOutlinedInput-input": {
      fontFamily: `'Titillium Web', sans-serif`,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      color: "#162C36",
      marginLeft: 10,
      background: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F1F6FA",
    },
    "& .MuiInputAdornment-positionEnd": {
      // marginRight: 10,
    },
    "& .MuiSvgIcon-root": {
      color: "#98ABB6",
      marginRight: 10,
    },
  },
  submitButton: {
    width: 176,
    height: 48,
    width: "100%",
    background: "#FF844B",
    border: "2px solid #FF844B",
    boxSizing: "border-box",
    borderRadius: "40px",
    boxShadow: "0px 4px 8px rgba(255, 72, 0, 0.24)",
    "& .MuiButton-label": {
      fontFamily: `'Titillium Web', sans-serif`,
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "&:hover": {
      backgroundColor: "#FF6D00",
    },
    "&:hover .MuiButton-label": {},
  },
  cancelButton: {
    width: 176,
    height: 48,
    width: "100%",
    background: "#4E7D96",
    border: "2px solid #4E7D96",
    boxSizing: "border-box",
    borderRadius: "40px",
    boxShadow: "0px 8px 24px -8px rgba(78, 125, 150, 0.24)",
    "& .MuiButton-label": {
      fontFamily: `'Titillium Web', sans-serif`,
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "&:hover": {
      backgroundColor: "#395C6F",
    },
    "&:hover .MuiButton-label": {},
  },
  [theme.breakpoints.down("sm")]: {
    search: {
      position: "relative",
      width: "100%",
      maxWidth: 550,
    },
    header: {
      padding: "0px 40px",
      maxHeight: "80px",
      height: "80px",
      paddingTop: "20px",
      paddingBottom: "20px",
    },
    searchVisibility: {
      visibility: "hidden",
    },
    // accountBoxLeft: {
    //   display: "flex",
    //   alignItems: "center",
    //   alignSelf: "center",
    //   "&>:nth-child(1)": {
    //     marginRight: 19
    //   },
    //   "&>:nth-child(2)": {
    //     height: 24,
    //     marginTop: '10px',
    //   }
    // },
  },
  [theme.breakpoints.down("xs")]: {
    header: {
      padding: "0px 40px",
      maxHeight: "80px",
      height: "80px",
      paddingTop: "20px",
      paddingBottom: "20px",
    },
    searchVisibility: {
      display: "none",
    },
    dialog: {
      zoom: "90%",
    },
  },
  headerTop: {
    height: 56,
    color: "#fff",
    fontStyle: "normal",
    backgroundImage: `url(${headerBack})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "center",
    paddingLeft: 40,
    paddingRight: 40,
    justifyContent: "space-between",
  },
  rightHeader: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
  },
  MenuItem: {
    padding: "13px 19px 13px 19px"
  },
  iconMenu: {
    width: 17,
    height: 17,
    marginRight: 20,
    color: "#98ABB6",
  },
});

export default styles;
