import { createContext, useContext, useEffect, useState } from "react";
import UserService from "services/UserService";
import ContextAuth from "contexts/Auth.context";

const AppAccessContext = createContext(null);

const AppAccessContextProvider = ({ children }) => {
    const {adminID} = ContextAuth();
    const [dataAccess, setDataAccess] = useState({
        1: false,
        2: false,
        3: false
    });
    const [loading, setLoading] = useState(null);

    const getAccess = async () => {
        setLoading(true)
        const data = await UserService.getAppAccess(adminID).catch((e) => console.error(e))
        console.log(data)
        const tempObj = {...dataAccess}
        if(Array.isArray(data)){
            data.map( item => tempObj[item.id] = item.access ? true : false )
        }
        setDataAccess({...tempObj})
        setLoading(false)
    }

    useEffect(() => {
        if(adminID) getAccess()
    }, [adminID]);

    return (
        <AppAccessContext.Provider
            value={{
                dataAccess,
                loading
            }}
        >   
            {children}
        </AppAccessContext.Provider>
    );
}

export { AppAccessContextProvider };

const ContextAppAccess = () => {
    return useContext(AppAccessContext);
}

export default ContextAppAccess;