import React, { useEffect } from 'react';
import ReactSuspenseContainer from "containers/ReactSuspense.container";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import ContextAuth from 'contexts/Auth.context';
import ContextAppAccess from 'components/useContext/AppAccess.context';

const LazyProjects = React.lazy(() => import("pages/Projects/views/Home"));
const LazyDashboard = React.lazy(() => import("components/dashboard/Dashboard"));
const LazyContacts = React.lazy(() => import("components/contacts/Contacts"));

const LazyCalendar = React.lazy(() => import("components/calendar/Calendar"));
const LazyErrorWindow = React.lazy(() => import("components/ErrorWindow"));
const LazyCredentials = React.lazy(() => import("components/Credentials"));
const LazyProfileIndex = React.lazy(() => import("components/profile/index"));

const LazyEdit = React.lazy(() => import("components/Edit/Edit"));

const LazyChatIndex = React.lazy(() => import("components/Chat"));
const UpdateList = React.lazy(() => import("pages/UpdateList/UpdateList"));
const UpdateDetail = React.lazy(() =>
    import("pages/UpdateDetail/UpdateDetail")
);
const LazyTerms = React.lazy(() => import("components/terms/TermsCond"));
const LazyTags = React.lazy(() => import("components/tags/Tags"));
const LazyCredits = React.lazy(() => import("pages/Credits"));
const LazyCreditPlans = React.lazy(() => import("pages/Credits/Plans"));

const AppRouter = () => {
    const location = useLocation();
    const {
        user, subscribe, initDataLogin, search, setSearch, setUserSelected, profilePhoto, projectName, setProjectName,
        userSelected, newMessage, sendingUser, lastMessageReceived, setSendingUser
    } = ContextAuth();
    const { dataAccess } = ContextAppAccess();

    const routerOnlyPlans = [
        {
            path: "/Profile/:newSubscriber",
            element: <ReactSuspenseContainer>
                <LazyProfileIndex
                    user={user}
                    subscribe={subscribe}
                    changeUser={initDataLogin}
                    profilePhoto={profilePhoto}
                    setUserSelected={setUserSelected}
                    tempEditValue="subscription"
                    planSelected={user.planSelected}
                />
            </ReactSuspenseContainer>,
        },
        {
            path: "*",
            element: <Navigate to="/Profile/info" />,
        },
    ]

    const routes = [
        {
            path: "/Projects",
            element: <ReactSuspenseContainer>
                <LazyProjects
                    user={user}
                    subscribe={subscribe}
                    changeUser={initDataLogin}
                    search={search}
                    setSearch={setSearch}
                />
            </ReactSuspenseContainer>,
        },
        {
            path: "/Dashboard",
            element: <ReactSuspenseContainer>
                {
                    (user.role === "Admin" ||
                        user?.have_additional_permissions)
                        ? <LazyDashboard
                            user={user}
                            setUserSelected={setUserSelected}
                        />
                        : <LazyErrorWindow />
                }
            </ReactSuspenseContainer>,
        },
        {
            path: "/Contacts",
            element: <ReactSuspenseContainer>
                <LazyContacts
                    user={user}
                    subscribe={subscribe}
                    changeUser={initDataLogin}
                    search={search}
                />
            </ReactSuspenseContainer>,
        },
        {
            path: "/Calendar",
            element: <ReactSuspenseContainer>
                {!["Free", "Single user"].includes(subscribe.name)
                    ? <LazyCalendar
                        user={user}
                        subscribe={subscribe}
                        changeUser={() => initDataLogin()}
                    />
                    : <Navigate to="/Profile/subscribe" />
                }
            </ReactSuspenseContainer>,
        },
        {
            path: "/Credentials",
            element: <ReactSuspenseContainer>
                <LazyCredentials user={user}/>
            </ReactSuspenseContainer>,
        },
        // {
        //     path: "/Credentials",
        //     element: <ReactSuspenseContainer>
        //         {
        //             user.role === "Admin" && dataAccess[1]
        //                 ? <LazyCredentials />
        //                 : <LazyErrorWindow />
        //         }
        //     </ReactSuspenseContainer>,
        // },
        {
            path: "/Profile/:newSubscriber",
            element: <ReactSuspenseContainer>
                <LazyProfileIndex
                    user={user}
                    subscribe={subscribe}
                    changeUser={initDataLogin}
                    profilePhoto={profilePhoto}
                    setUserSelected={setUserSelected}
                />
            </ReactSuspenseContainer>,
        },
        {
            path: "/Edit/Create-Project/:name/:address/:type",
            element: <ReactSuspenseContainer>
                <LazyEdit
                    user={user}
                    projectName={projectName}
                    setProjectName={setProjectName}
                />
            </ReactSuspenseContainer>,
        },
        {
            path: "/Edit/Project/:id",
            element: <ReactSuspenseContainer>
                <LazyEdit
                    user={user}
                    projectName={projectName}
                    setProjectName={setProjectName}
                />
            </ReactSuspenseContainer>,
        },
        {
            path: "/Edit/Project/:id/:ref",
            element: <ReactSuspenseContainer>
                <LazyEdit
                    user={user}
                    projectName={projectName}
                    setProjectName={setProjectName}
                />
            </ReactSuspenseContainer>,
        },
        {
            path: "/Chat/Messenger",
            element: <ReactSuspenseContainer>
                {!["Free", "Single user"].includes(subscribe.name)
                    ? <LazyChatIndex
                        user={user}
                        userSelected={userSelected}
                        setUserSelected={setUserSelected}
                        newMessage={newMessage}
                        sendingUser={sendingUser}
                        lastMessageReceived={lastMessageReceived}
                        setSendingUser={setSendingUser}
                    />
                    : <Navigate to="/Profile/subscribe" />
                }
            </ReactSuspenseContainer>,
        },
        {
            path: "/Update/List",
            element: <ReactSuspenseContainer>
                <UpdateList />
            </ReactSuspenseContainer>,
        },
        {
            path: "/Update/Detail/:id",
            element: <ReactSuspenseContainer>
                <UpdateDetail />
            </ReactSuspenseContainer>,
        },
        {
            path: "/Terms",
            element: <ReactSuspenseContainer>
                <LazyTerms />
            </ReactSuspenseContainer>,
        },
        {
            path: "/Tags",
            element: <ReactSuspenseContainer>
                <LazyTags />
            </ReactSuspenseContainer>,
        },
        {
            path: "/credits",
            element: <ReactSuspenseContainer>
                <LazyCredits />
            </ReactSuspenseContainer>,
        },
        {
            path: "/credits/plans",
            element: <ReactSuspenseContainer>
                <LazyCreditPlans />
            </ReactSuspenseContainer>,
        },
        {
            path: "*",
            element: <Navigate to="/Projects" />,
        },
    ];

    useEffect(() => {
        initDataLogin();
        setSearch(null);
    }, [location]);



    return useRoutes( 
        ["Single", "Business","Enterprise"].includes(user.planSelected) 
            ? routerOnlyPlans 
            : routes
    );
}

export default AppRouter;