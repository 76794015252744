import axios from "axios";
import { BASE_URL } from "utils/constants";

class UserService {
  async getloginUser(params) {
    let result;
    const url = `${BASE_URL}/api/user/login/sign`;

    await axios
      .post(url, params)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async loginUser(params) {
    let result;
    const url = `${BASE_URL}/api/user/login`;

    await axios
      .post(url, params)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async signupUser(params) {
    let result;
    const url = `${BASE_URL}/api/user/register`;

    await axios
      .post(url, params)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async signupUserLogin(params) {
    let result;
    const url = `${BASE_URL}/api/user/register/login`;

    await axios
      .post(url, params)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async signupOauth(params) {
    let result;
    const url = `${BASE_URL}/api/oauth/register`;

    await axios
      .post(url, params)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getUserTeam(limit) {
    let result;
    let url = `${BASE_URL}/api/user/get/team`;

    await axios
      .get(url, {
        params: {
          limit: limit,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getUserTeamSelect(limit) {
    let result;
    let url = `${BASE_URL}/api/user/get/team/select`;

    await axios
      .get(url, {
        params: {
          limit: limit,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getUsersRecent(limit) {
    let result;
    let url = `${BASE_URL}/api/user/get/recent`;

    await axios
      .get(url, {
        params: {
          limit: limit,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async getUserData() {
    let result;
    const url = `${BASE_URL}/api/user/get`;

    await axios
      .get(url)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async updateUser(data) {
    var result = null;
    const url = `${BASE_URL}/api/user/change/profile`;
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    await axios
      .post(url, data, config)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async updateUserPassword(params) {
    var result = null;
    const url = `${BASE_URL}/api/user/change/password`;

    await axios
      .put(url, params)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async deleteUser(currentPassword) {
    let result;
    const url = `${BASE_URL}/api/user/delete`;

    await axios
      .delete(url, {
        headers: { "Content-Type": "application/json" },
        data: {
          currentPassword: currentPassword,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async deleteUserMember(id) {
    let result;
    const url = `${BASE_URL}/api/user/delete/member`;

    await axios
      .delete(url, {
        params: {
          memberId: id,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        console.log(err);
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }

  async addPermissions(params) {
    let result;
    const url = `${BASE_URL}/api/user/add-additional-permissions`;

    await axios
      .post(url, params)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }


  async getAppAccess(adminID) {
    let result;
    const url = `${BASE_URL}/api/accessServices/${adminID}`;
  
    await axios
      .get(url)
      .then((res) => {
        const data = res.data;
        if(data.status === "success") result = data.data
      })
      .catch((err) => {
        result = null
      });
  
    return result;
  }

  async getMembers() {
    let result;
    let url = `${BASE_URL}/api/user/get/members`;

    await axios
      .get(url,)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
}

export default new UserService();
