import React from 'react';
import ContextAuth from 'contexts/Auth.context';
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import ReactSuspenseContainer from 'containers/ReactSuspense.container';
const LazyMeasuringTool = React.lazy(() => import("pages/Tools/measuringtool"));
const LazyOauthAuthorize = React.lazy(() => import("pages/oauth/authorize"));
const LazyOauthLogin = React.lazy(() => import("pages/oauth/login"));
const LazyOauthRegister = React.lazy(() => import("pages/oauth/register"));

const OauthRouter = () => {
    const { user, session,initDataLogin } = ContextAuth();
    const token = useSelector(state => state.token);
  
    const routes = [
      {
        path: "/oauth/authorize",
        element: <ReactSuspenseContainer>
          <LazyOauthAuthorize token={token}/>
        </ReactSuspenseContainer>,
      },
      {
        path: "/oauth/login",
        element: <ReactSuspenseContainer>
          <LazyOauthLogin session={session} action={initDataLogin} />
        </ReactSuspenseContainer>,
      },
      {
        path: "/oauth/register",
        element: <ReactSuspenseContainer>
          <LazyOauthRegister />
        </ReactSuspenseContainer>,
      },
      {
        path: "/tools/measuringtool/:name/:type",
        element: <ReactSuspenseContainer>
          <LazyMeasuringTool user={user} token={token} />
        </ReactSuspenseContainer>,
      },
      {
        path: "/tools/measuringtool/Edit/:id",
        element: <ReactSuspenseContainer>
          <LazyMeasuringTool user={user} token={token} />
        </ReactSuspenseContainer>,
      },
    ];
  
    return useRoutes(routes);
  }
  
  export default OauthRouter;