import { BlobServiceClient } from "@azure/storage-blob";
import Swal from "sweetalert2";
import optimizePhoto from "utils/util";
import {
  blob_azure_container,
  blob_azure_container_guidlines,
  sasToken,
  storageAccountName,
} from "./azure_credentials";

export const uploadReportPDFtoAzure = async (blob, userID, reportType = "") => {
  try {
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );

    const containerClient =
      blobService.getContainerClient(blob_azure_container);

    if ((await containerClient.exists()) === false) {
      await containerClient.createIfNotExists({
        access: "container",
      });
    }

    const blobClient = containerClient.getBlockBlobClient(
      `pdf_users/${userID}/${reportType}${userID}.pdf`
    );

    await blobClient.uploadBrowserData(blob);
  } catch (err) {
    console.log("ERROR AL SUBIR PDF.", err);
  }
};

export const saveReportPDFtoAzure = async (blob, userID, filename, reportType = "") => {
  try {
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );

    const containerClient =
      blobService.getContainerClient(blob_azure_container);

    if ((await containerClient.exists()) === false) {
      await containerClient.createIfNotExists({
        access: "container",
      });
    }

    const blobClient = containerClient.getBlockBlobClient(
      `pdf_users/${userID}/${reportType}${filename}.pdf`
    );

    await blobClient.uploadBrowserData(blob);
  } catch (err) {
    console.log("ERROR AL SUBIR PDF.", err);
  }
};

// `${AZURE_IMAGE_URL}/${projectId}/${imgs.photo_name}?t=${new Date().getTime()}`,
export const uploadImageAzure = async (arrayFile = [], projectID) => {
  if (arrayFile.length < 1) return;
  try {
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );

    const containerClient =
      blobService.getContainerClient(blob_azure_container);

    if ((await containerClient.exists()) === false) {
      await containerClient.createIfNotExists({
        access: "container",
      });
    }

    Promise.all(
      arrayFile.map(
        (file) =>
          new Promise(async (resolve) => {
            try {
              const blobClient = containerClient.getBlockBlobClient(
                `${projectID}/${file.name}`
              );

              const options = {
                blobHTTPHeaders: { blobContentType: file.type },
              };

              await blobClient.uploadBrowserData(file, options);
            } catch (err) {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Error",
                text: `Images could not be saved. There are problems with Azure`,
              });
            }
          })
      )
    );
  } catch (err) {
    console.log(err);
  }
};

export const updateImageAzure = async (file, projectID) => {
  try {
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );

    const containerClient =
      blobService.getContainerClient(blob_azure_container);

    if ((await containerClient.exists()) === false) {
      await containerClient.createIfNotExists({
        access: "container",
      });
    }

    const blobClient = containerClient.getBlockBlobClient(
      `${projectID}/${file.name}`
    );

    const options = {
      blobHTTPHeaders: { blobContentType: file.type },
    };

    await blobClient.uploadBrowserData(file, options);
  } catch (err) {
    console.log(err);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: `Image could not be saved. There are problems with Azure`,
    });
  }
};

export const downloadImageAzure = async (name) => {
  try {
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );
    const containerClient = blobService.getContainerClient(
      blob_azure_container_guidlines
    );

    if ((await containerClient.exists()) === false) {
      await containerClient.createIfNotExists({
        access: "container",
      });
    }
    console.log(
      name
        .split("/")
        .map((i) => i)
        .join("/")
    );
    const blobClient = containerClient.getBlockBlobClient(
      name
        .split("/")
        .map((i) => i)
        .join("/") /* `${projectID}/${file.name}`*/
    );

    const blobResponse = await blobClient.download();

    return await blobResponse.blobBody;
  } catch (err) {
    console.log(err);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: `Can't download image`,
    });
  }
};

export const uploadOptimizeImageAzure = async (file, adminId, size) => {
  try {
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );

    const containerClient = blobService.getContainerClient(
      blob_azure_container_guidlines
    );

    if ((await containerClient.exists()) === false) {
      await containerClient.createIfNotExists({
        access: "container",
      });
    }

    const blobClient = containerClient.getBlockBlobClient(
      `${adminId}/${file.name}`
    );

    const options = {
      blobHTTPHeaders: { blobContentType: file.type },
    };
    const optimizeFile = await optimizePhoto(file, size);
    await blobClient.uploadBrowserData(optimizeFile, options);
  } catch (err) {
    console.log(err);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: `Image could not be saved. There are problems with Azure`,
    });
  }
};

export const updateGuidlinesImageAzure = async (file, adminId) => {
  try {
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );

    const containerClient = blobService.getContainerClient(
      blob_azure_container_guidlines
    );

    if ((await containerClient.exists()) === false) {
      await containerClient.createIfNotExists({
        access: "container",
      });
    }

    const blobClient = containerClient.getBlockBlobClient(
      `${adminId}/${file.name}`
    );

    const options = {
      blobHTTPHeaders: { blobContentType: file.type },
    };

    await blobClient.uploadBrowserData(file, options);
  } catch (err) {
    console.log(err);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: `Image could not be saved. There are problems with Azure`,
    });
  }
};

export const cloneAzureImage = async (array = [], projectId) => {
  try {
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );

    const containerClient =
      blobService.getContainerClient(blob_azure_container);

    const destinationPrefix = `${projectId}/`;

    const copyPromises = [];

    for (const image of array) {
      const { id, newUrl } = image;

      const imageName = `${projectId}/${id}`;

      const sourceBlobClient = containerClient.getBlobClient(imageName);
      const destinationBlobName = destinationPrefix + newUrl;

      const destinationBlobClient =
        containerClient.getBlobClient(destinationBlobName);
      copyPromises.push(
        destinationBlobClient.beginCopyFromURL(sourceBlobClient.url)
      );
    }

    Promise.all(copyPromises)
      .then((e) => console.log("All copies complete.", e))
      .catch((error) => console.error("Error copying blobs:", error));
  } catch (err) {
    console.log(err);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: `Image could not be saved. There are problems with Azure`,
    });
  }
};
