import ReactGA from "react-ga";
import React, { useState, useEffect, useContext } from "react";
import { compose } from "@nivo/recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { removeToken } from "redux/actions/token";
import { Link } from "react-router-dom";
import InputBase from "@material-ui/core/InputBase";
import {
  Grid,
  Hidden,
  Menu,
  MenuItem,
  Divider,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Close from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
//Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import iconHome from "assets/icons/icon-home.png";
//add component
import Box from "@material-ui/core/Box";
import BootstrapTooltip from "components/customs/BootstrapToolTip";

import imgLogo from "assets/images/Logo.png";
import { useLocation } from "react-router-dom";
//Service
import style from "./style";
import Footer from "components/footer/Footer";

import iconLogout from "assets/images/icon-logout.png";
import iconProject from "assets/images/icon-project.png";
import iconSetting from "assets/images/icon-setting.png";
import iconMessenger from "assets/icons/icon-messenger.png";
import iconCalendar from "assets/icons/icon-calendar.png";
import iconContacts from "assets/icons/icon-contacts.png";
import iconDashboard from "assets/icons/icon-dashboard.png";
import { format } from "date-fns";

import SubscriptionService from "services/SubscriptionService";
import NotifyService from "services/NotifyService";
import { socket } from "utils/socket";
import { ChangeContext } from "components/useContext/ChangeContext";
import { OutputDialog } from "components/outputDialog/OutputDialog";
import Notifications from "components/notifications/Notifications";
import useSessionStorage from "Hooks/useSessionStorage";
import CompanyCampService from "services/CompanyCampService";
import {
  closeMessageBox,
  getValueOrDefault,
  isNullOrEmpty,
  messageBox,
  openLoading,
  setCRMTokenValue,
  validateToken,
} from "utils/util";
import { typeOfResponse, typeWebHook } from "utils/constants";
import GuidlinesService from "services/GuidlinesService";
import VpnKeyIcon from "@material-ui/icons/GroupWork";
import UpdateIcon from "@material-ui/icons/Update";
import app_CompanyCamService from "services/app_CompanyCamService";
import Swal from "sweetalert2";
import ContextAppAccess from "components/useContext/AppAccess.context";
import CreditsCard from "components/Credits/credits";
import { APICRM } from "services/apicrm.service";

const ITEM_HEIGHT = 48;

function Header(props) {
  const {
    classes,
    removeToken,
    subscribe,
    user,
    setSearch,
    changeUser,
    projectName,
    profilePhoto,
    setNewMessage,
    setSession,
    userId,
    setUserId,
    device,
    children,
    setSendingUser,
    search,
    setLastMessageReceived,
  } = props;
  const {
    isChangedGeneralConditions,
    isChangedRoofComposition,
    isChangedOverviewPictures,
    isChangedRoofSlope,
    isChangedRoofDrainage,
    isChangedwallDetails,
    isChangedRoofDetails,
    isChangeProjectInformation,
    setOptionFilterChange,
    setNumberOfFiltres,
    setOnlineUserChat,
    setOnlineUserChatDisconnect,
    setNew_comment,
    setChangeMesage,
    setNewChatGroupNotification,
    setDeleteChatGroupNotification,
    setUpdateChatGroupNotifications,
    changeTextSearch,
    setchangeTextSearch,
    localSearch,
    setLocalSearch,
    isFirtsRenderHeader,
    setisFirtsRenderHeader,
    ss_search,
    setSs_search,
    removeSs_search,
    removeSs_optionFilter,
    removeSs_numberOfFiltres,
    setLs_token_companyCamp,
    ls_token_companyCamp,
    removeLs_Token_companyCamp,
    guidlinesImages,
    setGuidlinesImages,
    setUpdateCompanycamIdAfterReload,
    changeDrawing,
  } = useContext(ChangeContext);
  const [anchorAccountElement, setAnchorAccountElement] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  // const [localSearch, setLocalSearch] = useState(null);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  // const [isFirtsRenderHeader, setisFirtsRenderHeader] = useState(true);
  // const [, setSs_search] = useSessionStorage('search', '');
  const { dataAccess } = ContextAppAccess();

  const getNotifications = async () => {
    const res = await NotifyService.getNotify();
    if (res.status === "success") {
      setCount(res.data.count);
      setNotifications(res.data.notifications);
    } else {
      setNotifications([]);
    }
  };
  const handleSearch = (event) => {
    let inputValue = event.target.value;
    //setSearch(inputValue);
    setLocalSearch(inputValue);
    // setOptionFilterChange(false);
    // setNumberOfFiltres(0);
  };

  const handleCloseMessage = async () => {
    const res = await SubscriptionService.closeMessageSubscription();
    if (res.status === "success") {
      changeUser();
    }
  };

  const handleLogout = () => {
    const activeDate = Date.now();
    let data = {
      user_id: user.user_id,
      active_date: activeDate,
      active: 0,
    };
    if (user.login === "facebook") {
      window.FB.api("/me/permissions", "delete", null, () =>
        window.FB.logout()
      );
    }
    socket.emit("logout", data);
    removeToken();
    socket.removeAllListeners();
    socket.disconnect();
    socket.close();
    setUserId(null);
    removeSs_search();
    removeSs_optionFilter();
    removeSs_numberOfFiltres();
    removeLs_Token_companyCamp();
    navigate(0);
  };

  const onStream = () => {
    socket.connect();
    socket.on("connect", () => {
      console.log("connected");
    });

    socket.on("new_message", (data) => {
      setNewMessage(data);
    });

    socket.on("notifications", (data) => {
      if (data.notify) {
        getNotifications();
      }
    });

    socket.on("lastMessage", (data) => {
      if (data) {
        setLastMessageReceived(data);
      }
    });

    socket.on("onlineUser", (data) => {
      setOnlineUserChat(data);
    });

    socket.on("disconnectUser", (data) => {
      setOnlineUserChatDisconnect(data);
    });

    socket.on("new_comment", (data) => {
      setNew_comment(data);
    });

    socket.on("it_was_read_message", (data) => {
      setChangeMesage({
        change: true,
        sender: data.sender,
        receiver: data.user.userId,
      });
    });

    socket.on("new_group_message", (data) => {
      setNewChatGroupNotification(data);
    });

    socket.on("delete_group_message", (data) => {
      setDeleteChatGroupNotification(data);
    });

    socket.on("new_update_group", (data) => {
      setUpdateChatGroupNotifications(data);
    });
  };

  const onStreamDevice = (device) => {
    socket.on("device", (data) => {
      if (device) {
        if (device !== data.device) {
          removeToken();
          if (user.login === "facebook") {
            window.FB.api("/me/permissions", "delete", null, () =>
              window.FB.logout()
            );
          }
          socket.removeAllListeners();
          socket.disconnect();
          socket.close();
          setUserId(null);
          removeLs_Token_companyCamp();
          setSession("You are connected to another device");
        }
      }
    });
  };

  const onStreamEmit = (userId) => {
    if (userId) {
      socket.emit("join", { userId: userId });
      socket.emit("online", userId);
    }
  };

  const handleClick = (event) => {
    if (notifications.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotify = async (item) => {
    setAnchorEl(null);
    if (item.type_notify === "mention") {
      navigate(`/Edit/Project/${item.projectId}/${item.ref_id}`);
    } else {
      setSendingUser(item?.senderId);
      navigate(`/Chat/Messenger`);
    }
    const data = {
      id: item.id,
      readAll: false,
    };
    const res = await NotifyService.updateNotify(data);
    if (res.status === "success") {
      getNotifications();
    }
  };

  useEffect(() => {
    getNotifications();
    onStream();
    getGuidlinesImage();
    loadCRMToken();
    return () => {
      setisFirtsRenderHeader(true);
    };
  }, []);

  const getGuidlinesImage = async () => {
    try {
      const response = await GuidlinesService.getGuidlinesImages();
      const { status, data } = response;
      if (status !== "success") return;
      setGuidlinesImages(data);
    } catch (err) {
      console.log(err);
    }
  };

  const loadCRMToken = async () => {
    APICRM.getTokenCRM()
      .then(({status, data, message})=>{     
        if(status != 'success') {
          setCRMTokenValue("");
          return;
        }

        if(isNullOrEmpty(data)) {
          setCRMTokenValue("");
          return;
        }

        setCRMTokenValue(getValueOrDefault(data?.access_token, ""));
      })
      .catch((err) => {
        console.log(err);
        setCRMTokenValue("");
      });
  };

  useEffect(() => {
    onStreamDevice(device);
  }, [device]);

  useEffect(() => {
    onStreamEmit(userId);
  }, [userId]);

  let timer;
  useEffect(() => {
    // alert('render search');

    // let timer = setTimeout(() => {
    // setSearch(localSearch);
    // if (localSearch !== null && location.pathname.indexOf("Projects") === 1) sessionStorage.setItem('search', JSON.stringify(localSearch));
    // }, 1500)

    // return () => clearTimeout(timer)

    if (location.pathname.indexOf("Projects") === 1) {
      if (isFirtsRenderHeader) {
        ReactGA.event({
          category: "Search",
          action: "a search was made",
        });
        setSearch(localSearch);
        // if (localSearch !== null) sessionStorage.setItem('search', JSON.stringify(localSearch));
      } else {
        timer = setTimeout(() => {
          ReactGA.event({
            category: "Search",
            action: "a search was made",
          });
          setSearch(localSearch);
          if (localSearch !== null) setSs_search(localSearch);
          // if (localSearch !== null) sessionStorage.setItem('search', JSON.stringify(localSearch));
        }, 1500);
      }
    } else {
      timer = setTimeout(() => {
        ReactGA.event({
          category: "Search",
          action: "a search was made",
        });
        setSearch(localSearch);
      }, 1500);
    }

    return () => clearTimeout(timer);
  }, [localSearch, changeTextSearch]);

  useEffect(() => {
    setisFirtsRenderHeader(true);
    if (ls_token_companyCamp === null) getToken();
    else syncValidateToken();
  }, [location]);

  const getToken = async () => {
    try {
      const queryString = location.search;
      const urlParams = new URLSearchParams(queryString);

      if (!urlParams.has("code")) return;

      const projectName = sessionStorage.getItem("projectName");

      openLoading(
        isNullOrEmpty(projectName)
          ? "Loading..."
          : `Redirecting to project:  ${projectName}`,
        "Connecting with CompanyCam..."
      );

      sessionStorage.removeItem("projectName");

      const code = urlParams.get("code");
      const response = await CompanyCampService.getToken(code);
      const { status, data } = response;

      if (!status) {
        setLs_token_companyCamp(null);
        return closeMessageBox();
      }

      setLs_token_companyCamp(data);
      const { access_token } = data;

      await recordSessionInformation(access_token);

      app_CompanyCamService
        .addOrUpdateTokenCompanyCam(data)
        .catch((err) => console.log(err));

      const projectId = sessionStorage.getItem("projectId");

      if (isNullOrEmpty(projectId)) return closeMessageBox();

      app_CompanyCamService
        .addProjectToCompanyCam({
          access_token,
          projectId,
        })
        .then((res) => {
          const { status, message, companyCamId } = res;

          if (status !== typeOfResponse.success)
            return Swal.fire({
              icon: "error",
              title: "Error",
              text: message,
              allowOutsideClick: false,
              didClose: function () {
                sessionStorage.removeItem("projectId");
                navigate(`/Edit/Project/${projectId}`);
              },
            });

          sessionStorage.removeItem("projectId");
          setUpdateCompanycamIdAfterReload({
            status: true,
            value: companyCamId,
          });
          closeMessageBox();
          navigate(`/Edit/Project/${projectId}`);
        })
        .catch((err) => {
          closeMessageBox();
          sessionStorage.removeItem("projectId");
          console.log(err);
        });
    } catch (err) {
      closeMessageBox();
      console.log(err);
    }
  };

  const syncValidateToken = async () => {
    try {
      const response = await validateToken(ls_token_companyCamp);

      if (typeof response === "undefined") return;

      if (response === null) {
        setLs_token_companyCamp(null);
        navigate(0);
      }

      setLs_token_companyCamp(response);
      app_CompanyCamService.addOrUpdateTokenCompanyCam(response);
    } catch (err) {
      console.log(err);
    }
  };

  const recordSessionInformation = async (token) => {
    if (!token || token === null) return;
    try {
      const current_user = await CompanyCampService.getCurrentUser(token);
      const { status, data } = current_user;
      if (status) {
        const { id, company_id } = data;
        CompanyCampService.updateInfoCompanycamp({
          id,
          company_id,
          token,
          typeWebHook,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const validateToken = async () => {
  //   const { created_at, expires_in, refresh_token } = ls_token_companyCamp;
  //   const currentDate = Math.floor(new Date().getTime() / 1000);
  //   const isValid = currentDate < created_at + expires_in;
  //   if (isValid) return;
  //   const response = await CompanyCampService.refreshToken(refresh_token);
  //   const { status, data } = response;
  //   setLs_token_companyCamp(status ? data : null);
  // };

  const handleExitProject = () => {
    if (
      isChangedGeneralConditions ||
      isChangedRoofComposition ||
      isChangedOverviewPictures ||
      isChangedRoofSlope ||
      isChangedRoofDrainage ||
      isChangedwallDetails ||
      isChangedRoofDetails ||
      isChangeProjectInformation ||
      changeDrawing
    ) {
      setCancelDialogOpen(true);
    } else {
      navigate("/Projects");
    }
  };

  return (
    <div className={classes.container}>
      <Grid
        className={classes.container}
        container
        direction="column"
        wrap="nowrap"
      >
        {location.pathname.indexOf("Edit") === 1 ? (
          <Grid
            container
            direction="row"
            alignItems="stretch"
            className={classes.headerTop}
            wrap="nowrap"
          >
            <div className={classes.rightHeader}>
              {/* <Link to={`/Projects`} style={{ textDecoration: "none" }}> */}
              <BootstrapTooltip title="Go to Projects">
                <IconButton
                  color="default"
                  aria-label="close"
                  style={{ color: "#fff" }}
                  onClick={handleExitProject}
                >
                  <img
                    alt="home"
                    src={iconHome}
                    style={{ width: 20, height: 17 }}
                  />
                </IconButton>
              </BootstrapTooltip>
              {/* </Link> */}
              <Divider
                orientation="vertical"
                flexItem
                style={{
                  backgroundColor: "white",
                  width: 2,
                  height: 25,
                  margin: "5px 18px 0 18px",
                }}
              />
              <div className={classes.projectName}>{projectName}</div>
            </div>

            <div>
              <Notifications
                setSendingUser={setSendingUser}
                classes={classes}
                fromWhereItIsInvoked="detailProject"
              />
            </div>
          </Grid>
        ) : (
          <Grid
            className={classes.header}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Link
              to="/Projects"
              style={{ display: "flex", textDecoration: "none" }}
            >
              {/* <img
                src={imgLogo}
                alt="Logo"
                className={classes.logo}
                style={{ width: "124.12px", height: 40 }}
              /> */}
            </Link>
            <div style={{ flex: 1 }}></div>
            <div className={classes.accountBoxLeft}>
              {location.pathname.indexOf("Projects") === 1 ||
              location.pathname.indexOf("Contacts") === 1 ? (
                <div className={classes.searchVisibility}>
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      placeholder={
                        location.pathname.indexOf("Projects") === 1
                          ? "Search project name"
                          : "Search contact"
                      }
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ "aria-label": "search" }}
                      value={localSearch}
                      onChange={handleSearch}
                      onKeyDown={() => {
                        if (location.pathname.indexOf("Projects") === 1)
                          setisFirtsRenderHeader(false);
                      }}
                    />
                  </div>
                </div>
              ) : null}

              {user.planSelected === "" ? <CreditsCard /> : <></>}

              <Notifications
                setSendingUser={setSendingUser}
                classes={classes}
                fromWhereItIsInvoked="header"
              />

              {/*<Divider orientation="vertical" flexItem />*/}

              <div
                className={classes.accountBox}
                onClick={(e) => setAnchorAccountElement(e.currentTarget)}
              >
                <Avatar
                  alt={user.name}
                  src={profilePhoto}
                  style={{ height: "40px", width: "40px" }}
                />

                <Hidden xsDown>
                  <div>
                    <div style={{ marginRight: 10 }}>{user.name}</div>
                    <div>{user.company_name}</div>
                  </div>
                </Hidden>

                <ExpandMoreIcon fontSize="small" />
              </div>
            </div>
          </Grid>
        )}
        <Menu
          id="notify"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{
            textDecoration: "none",
          }}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 6,
              width: "30ch",
            },
          }}
        >
          <List className={classes.root}>
            {notifications.length > 0
              ? notifications.map((item, index) => (
                  <ListItem
                    key={index}
                    alignItems="flex-start"
                    onClick={() => handleNotify(item)}
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        item.read_notify === 0 ? "#ECF0F1" : "#fff",
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          variant="body2"
                          style={{
                            color:
                              item.read_notify === 0 ? "#0B45D0" : "#3B5D6E",
                          }}
                        >
                          {item.sender}
                        </Typography>
                      }
                      color=""
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            style={{
                              color: "#3B5D6E",
                            }}
                          >
                            {item.projectName}
                          </Typography>
                          {` — ${item.message}`}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                ))
              : null}
          </List>
        </Menu>

        <Menu
          id="account-menu"
          anchorEl={anchorAccountElement}
          keepMounted
          open={Boolean(anchorAccountElement)}
          onClose={() => setAnchorAccountElement(null)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {user ? (
            user.role === "Admin" || user.have_additional_permissions ? (
              <Link
                to="/Dashboard"
                onClick={() => setAnchorAccountElement(null)}
                style={{ textDecoration: "none" }}
              >
                <MenuItem style={{ padding: "13px 19px 13px 19px" }}>
                  <img
                    alt="Dashboard"
                    src={iconDashboard}
                    style={{ width: 17, height: 17, marginRight: 20 }}
                  />
                  <span className={classes.menuItemDesc}>Dashboard</span>
                </MenuItem>
              </Link>
            ) : null
          ) : null}
          <Link
            to="/Projects"
            onClick={() => setAnchorAccountElement(null)}
            style={{ textDecoration: "none" }}
          >
            <MenuItem style={{ padding: "13px 19px 13px 19px" }}>
              <img
                alt="Projects"
                src={iconProject}
                style={{ width: 17, height: 17, marginRight: 20 }}
              />
              <span className={classes.menuItemDesc}>Projects</span>
            </MenuItem>
          </Link>

          <Link
            to="/Contacts"
            onClick={() => setAnchorAccountElement(null)}
            style={{ textDecoration: "none" }}
          >
            <MenuItem style={{ padding: "13px 19px 13px 19px" }}>
              <img
                alt="Contacts"
                src={iconContacts}
                style={{ width: 17, height: 17, marginRight: 20 }}
              />
              <span className={classes.menuItemDesc}>Contacts</span>
            </MenuItem>
          </Link>
          <Link
            to="/Calendar"
            onClick={() => setAnchorAccountElement(null)}
            style={{ textDecoration: "none" }}
          >
            <MenuItem style={{ padding: "13px 19px 13px 19px" }}>
              <img
                alt="Calendar"
                src={iconCalendar}
                style={{ width: 17, height: 17, marginRight: 20 }}
              />
              <span className={classes.menuItemDesc}>Calendar</span>
            </MenuItem>
          </Link>
          <Link
            to="/Chat/Messenger"
            onClick={() => setAnchorAccountElement(null)}
            style={{ textDecoration: "none" }}
          >
            <MenuItem style={{ padding: "13px 19px 13px 19px" }}>
              <img
                alt="Chat"
                src={iconMessenger}
                style={{ width: 17, height: 17, marginRight: 20 }}
              />
              <span className={classes.menuItemDesc}>Chat/Messenger</span>
            </MenuItem>
          </Link>
          <Link
            to="/Profile/info"
            onClick={() => setAnchorAccountElement(null)}
            style={{ textDecoration: "none" }}
          >
            <MenuItem style={{ padding: "13px 19px 13px 19px" }}>
              <img
                alt="Setting"
                src={iconSetting}
                style={{ width: 17, height: 17, marginRight: 20 }}
              />
              <span className={classes.menuItemDesc}>Setting</span>
            </MenuItem>
          </Link>
          {/* {dataAccess[1] && user.role === "Admin" && ( */}
          <Link
            to="/Credentials"
            onClick={() => setAnchorAccountElement(null)}
            style={{ textDecoration: "none" }}
          >
            <MenuItem className={classes.MenuItem}>
              <VpnKeyIcon className={classes.iconMenu} />
              <span className={classes.menuItemDesc}>Integrations</span>
            </MenuItem>
          </Link>
          {/* )} */}
          <Link
            to="/Update/List"
            onClick={() => setAnchorAccountElement(null)}
            style={{ textDecoration: "none" }}
          >
            <MenuItem className={classes.MenuItem}>
              <UpdateIcon className={classes.iconMenu} />
              <span className={classes.menuItemDesc}>Updates</span>
            </MenuItem>
          </Link>
          <MenuItem
            onClick={handleLogout}
            style={{ padding: "13px 19px 13px 19px" }}
          >
            <img
              alt="Logout"
              src={iconLogout}
              style={{ width: 17, height: 17, marginRight: 20 }}
            />
            <span className={classes.menuItemDesc}>Logout</span>
          </MenuItem>
        </Menu>
        <Box height="100%" mx={0.5} width={120} display="inline-block"></Box>
        <Footer />
      </Grid>
      {children}
      {location.pathname.indexOf("Edit") === 1 ? null : subscribe.notify ===
          "active" && user.role === "Admin" ? (
        <div
          style={{
            display: user.planSelected === "" ? "grid" : "none",
            with: "100%",
            marginTop: 70,
            marginBottom: 10,
            paddingLeft: 15,
            paddingRight: 15,
            backgroundColor: "#FDE8E3", //FDE8E3
            position: "relative",
            zIndex: 1,
          }}
        >
          <div
            style={{
              fontFamily: "Titillium Web",
              fontSize: 14,
              with: "100%",
              textAlign: "center",
              marginTop: 10,
              paddingRight: 15,
            }}
          >
            {subscribe.status !== "free"
              ? "Your payment has been processed successfully."
              : ""}{" "}
            Your current plan is{" "}
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {subscribe.status !== "trialing"
                ? subscribe.name
                : `${subscribe.name} Trial Until ${format(
                    subscribe.period_end * 1000,
                    "MMMM dd yyyy"
                  )}`}
            </span>
          </div>

          <div
            style={{
              with: "100%",
              textAlign: "end",
              marginTop: -30,
            }}
          >
            {subscribe.status !== "trialing" ? (
              <IconButton aria-label="close" onClick={handleCloseMessage}>
                <Close />
              </IconButton>
            ) : (
              <div style={{ height: 40, width: 20 }}></div>
            )}
          </div>
        </div>
      ) : null}
      <OutputDialog
        cancelDialogOpen={cancelDialogOpen}
        setCancelDialogOpen={setCancelDialogOpen}
        subtitle={`You haven't saved the changes yet. Are you sure you want to cancel?`}
        cancelButtonText={"No"}
        buttonConfirm={true}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { token } = state;
  return { token };
};

const mapDispatchToProps = { removeToken };

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
