import axios from "axios";
import { BASE_URL } from "utils/constants";

class GuidlinesService {
  async getGuidlinesImages() {
    let result;
    const url = `${BASE_URL}/api/guidlines-image/get-list`;
    try {
      const response = await axios.get(url);
      result = response.data;
    } catch (err) {
      result = {
        status: "desconnected",
        message: "Could not connect to service",
      };
    }
    return result;
  }

  async createGuidlinesImages(body) {
    let result;
    const url = `${BASE_URL}/api/guidlines-image/create`;
    try {
      const response = await axios.post(url, body);
      result = response.data;
    } catch (err) {
      result = {
        status: "desconnected",
        message: "Could not connect to service",
      };
    }
    return result;
  }

  async deleteGuidlinesImages(body) {
    let result;
    const url = `${BASE_URL}/api/guidlines-image/delete`;
    try {
      const response = await axios.delete(url, { params: body });
      result = response.data;
    } catch (err) {
      result = {
        status: "desconnected",
        message: "Could not connect to service",
      };
    }
    return result;
  }
}

export default new GuidlinesService();
