import axios from "axios";
import { BASE_URL } from "utils/constants";

class app_countsService {
  async getMyCounts() {
    let result;
    const url = `${BASE_URL}/api/icons/counts/getMyIcons`;

    await axios
      .get(url)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
  async addCount(data) {
    let result;
    const url = `${BASE_URL}/api/icons/counts/addIcon`;

    await axios
      .post(url, data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
  async addNewIcon(data) {
    let result;
    const url = `${BASE_URL}/api/icons/counts/addNewIcon`;

    await axios
      .post(url, data)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
  async resetCount(nOrder) {
    let result;
    const url = `${BASE_URL}/api/icons/counts/resetIcon/${nOrder}`;

    await axios
      .get(url)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "desconnected",
          message: "No internet connection",
        };
      });

    return result;
  }
}

export default new app_countsService();