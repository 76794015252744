
import { ChangeProvider } from "./useContext/ChangeContext";
import { MapCountsProvider } from "./useContext/MapCountsContext";
import { TotalPicturesContextProvider } from "./useContext/TotalPictures.context";
import { CreditsContextProvider } from "contexts/Credits.context";
import { SettingsContextProvider } from "contexts/Settings.context";
import { MapInfoContextProvider } from "./useContext/MapInfo.context";


const ContextsContainers = ({ children }) => {
    return (
        <SettingsContextProvider>
            <CreditsContextProvider>
                <TotalPicturesContextProvider>
                    <MapCountsProvider>
                        <MapInfoContextProvider>
                        <ChangeProvider>
                            {children}
                        </ChangeProvider>
                        </MapInfoContextProvider>
                    </MapCountsProvider>
                </TotalPicturesContextProvider>
            </CreditsContextProvider>
        </SettingsContextProvider>
    )
}

export default ContextsContainers