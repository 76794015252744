import React from 'react';
import { CircularProgress } from "@material-ui/core"

const ReactSuspenseContainer = ({children, JSXloading = null}) => {
    return (
        <React.Suspense
            fallback={
                JSXloading 
                ? <JSXloading/> 
                : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "4rem" }}>
                    <CircularProgress size={22} />
                </div>
            }
        >
            {children}
        </React.Suspense>
    )
}

export default ReactSuspenseContainer